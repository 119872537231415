/* eslint-disable no-sparse-arrays */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Container from "../../components/container.component";
import Api from "../../services/api.service";
import GoBack from "../../components/goback.component";
import Switch from "react-switch";
import Chart from "../../components/chart/chart.component";
import GradientButton from "../../components/gradient.button";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import moment from "moment";
import {
  Body,
  NextDivisor,
  LeaderboardWrapper,
  Leaderboard,
  LeaderboardPosition,
  AssociationsNumber,
  LeaderboardPositionWrapper,
  UpArrow,
  DownArrow,
  RegisteredAssociationsText,
  RegisteredAssociationsWrapper,
  ProfilePlus,
  MegaphoneIcon,
  GeneralWrapper,
  SwitchWrapper,
  SwitchText,
  Wallpaper,
  UserInfoTeam,
  IpohWrapper,
  IpohText,
  IpohTime,
  UserRow,
  IconColumn,
  ProfilePlusLittle,
  MegaphoneIconLittle,
  UsernameMember,
  Number,
  ShowAllWrapper,
  RegisteredManifestationsText,
  ManifestationsNumber,
  RegisteredManifestationsWrapper
} from "../home/home.style";

function Team() {
  const history = useHistory();
  const [profile, setProfile] = useState();
  const [date, setDate] = useState();
  const [members, setMembers] = useState();
  const [leaderboardType, setLeaderboardType] = useState("month");
  const [leaderboard, setLeaderboard] = useState();
  const [chartType, setChartType] = useState("month");
  const [statistics, setStatistics] = useState();
  const [options, setOptions] = useState();

  const getProfile = async () => {
    try {
      const res = await Api.getProfile();
      setProfile(res.data);
    } catch (e) {
      // console.log(e.message);
    }
  };

  const getTeamMembers = async () => {
    try {
      const res = await Api.listUsers(true);
      setMembers(res.data);
    } catch (e) {
      // console.log(e.message);
    }
  };

  const fetchLists = async (type) => {
    const dateMin = moment().startOf(type);
    const dateMax = moment().endOf(type);
    const [
      { data: locData },
      { data: evtData },
      { data: statData },
      { data: stat2Data },
    ] = await Promise.all([
      Api.leaderboardTeamLocations(dateMin, dateMax, profile.user.manager_id),
      Api.leaderboardTeamEvents(dateMin, dateMax, profile.user.manager_id),
      Api.statisticsLocationTeam(profile.user.id),
      Api.statisticsEventTeam(profile.user.id),
    ]);
    setStatistics({
      locations: statData || {},
      events: stat2Data || {},
    });
    setLeaderboard({
      locations: locData || {},
      events: evtData || {},
    });
  };

  const changeLeaderboard = (type) => {
    setLeaderboardType(type ? "year" : "month");
    fetchLists(type ? "year" : "month");
  };

  const changeChart = async (type) => {
    if (type === "month") {
      let months = {
        1: { locations: null, events: null },
        2: { locations: null, events: null },
        3: { locations: null, events: null },
        4: { locations: null, events: null },
        5: { locations: null, events: null },
        6: { locations: null, events: null },
        7: { locations: null, events: null },
        8: { locations: null, events: null },
        9: { locations: null, events: null },
        10: { locations: null, events: null },
        11: { locations: null, events: null },
        12: { locations: null, events: null },
      };
      const filteredLocations = statistics.locations.statistics_by_month.filter(
        (key) => {
          return key.year === moment().year();
        }
      );
      const filteredEvents = statistics.events.statistics_by_month.filter(
        (key) => {
          return key.year === moment().year();
        }
      );
      filteredLocations.map((month) => {
        months[month.month].locations = month.total;
      });
      filteredEvents.map((month) => {
        months[month.month].events = month.total;
      });
      setChartType("month");
      setOptions({
        data: {
          labels: [
            "",
            "gen",
            "feb",
            "mar",
            "apr",
            "mag",
            "giu",
            "lug",
            "ago",
            "set",
            "ott",
            "nov",
            "dic",
          ],
          datasets: [
            {
              label: "  Associazioni",
              backgroundColor: "#B40000",
              borderColor: "#B40000",
              fill: false,
              lineTension: 0,
              borderWidth: 2,
              pointBorderWidth: 6,
              pointHoverBorderWidth: 6,
              data: [
                ,
                months[1].locations,
                months[2].locations,
                months[3].locations,
                months[4].locations,
                months[5].locations,
                months[6].locations,
                months[7].locations,
                months[8].locations,
                months[9].locations,
                months[10].locations,
                months[11].locations,
                months[12].locations,
              ],
            },
            {
              label: "  Manifestazioni",
              backgroundColor: "#0071BC",
              borderColor: "#0071BC",
              fill: false,
              lineTension: 0,
              borderWidth: 2,
              pointBorderWidth: 6,
              pointHoverBorderWidth: 6,
              data: [
                ,
                months[1].events,
                months[2].events,
                months[3].events,
                months[4].events,
                months[5].events,
                months[6].events,
                months[7].events,
                months[8].events,
                months[9].events,
                months[10].events,
                months[11].events,
                months[12].events,
              ],
            },
          ],
        },
      });
    } else if (type === "year") {
      let years = {};
      statistics.locations.statistics_by_year.map((year) => {
        years[year.year] = { ...years[year.year], locations: year.total };
      });
      statistics.events.statistics_by_year.map((year) => {
        years[year.year] = { ...years[year.year], events: year.total };
      });
      const emptyYear = { locations: 0, events: 0 };
      const yearsKeys = Object.keys(years);
      if(yearsKeys.length > 1) {
        for(let i = yearsKeys[0]; i < yearsKeys[yearsKeys.length - 1]; i++) {
          if(i !== yearsKeys[0] && i !== yearsKeys[yearsKeys.length - 1]) years[i] = emptyYear;
        }
      }
      setChartType("year");
      setOptions({
        data: {
          labels: Object.keys(years),
          datasets: [
            {
              label: "  Associazioni",
              backgroundColor: "#B40000",
              borderColor: "#B40000",
              fill: false,
              lineTension: 0,
              borderWidth: 2,
              pointBorderWidth: 6,
              pointHoverBorderWidth: 6,
              data: Object.values(years).map(y => y.locations),
            },
            {
              label: "  Manifestazioni",
              backgroundColor: "#0071BC",
              borderColor: "#0071BC",
              fill: false,
              lineTension: 0,
              borderWidth: 2,
              pointBorderWidth: 6,
              pointHoverBorderWidth: 6,
              data: Object.values(years).map(y => y.events),
            },
          ],
        },
      });
    }
  };

  const visualizeAll = (role) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    history.push({ pathname: "/listmembers", state: role });
  };

  const visualizeOne = (id) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    history.push({ pathname: "/profile", state: id });
  };

  async function fetchStats() {
    const [{ data: locData }, { data: evtData }] = await Promise.all([
      Api.statisticsLocationTeam(profile.user.id),
      Api.statisticsEventTeam(profile.user.id),
    ]);
    setStatistics({
      locations: locData || {},
      events: evtData || {},
    });
  }

  useEffect(() => {
    getProfile();
    getTeamMembers();
  }, []);

  useEffect(() => {
    if (profile !== undefined) {
      fetchStats();
      setDate(
        moment(profile.user.created_at).format("ll").toUpperCase().split(" ")
      );
      fetchLists(leaderboardType);
    }
  }, [profile]);

  useEffect(() => {
    if (statistics !== undefined) {
      changeChart("month");
    }
  }, [statistics]);

  if (leaderboard) {
    return (
      <>
        <GoBack />
        <Body>
          <Container
            title={"Il tuo team"}
            content={
              <>
                <Wallpaper />
                <UserInfoTeam>
                  <IpohWrapper>
                    <IpohText>Creato</IpohText>
                    <IpohTime>{date[1] + " " + date[2]}</IpohTime>
                  </IpohWrapper>
                </UserInfoTeam>
              </>
            }
          ></Container>
          <Container
            title={"Situazione Classifiche Team"}
            isTime
            leaderboardType={leaderboardType}
            content={
              <>
                <GeneralWrapper>
                  <LeaderboardWrapper>
                    <Leaderboard style={{ marginRight: 17, marginLeft: 14.5 }}>
                      <LeaderboardPositionWrapper>
                        <LeaderboardPosition>
                          Pos in classifica{" "}
                          {leaderboard.locations !== "N\\D"
                            ? leaderboard.locations.position
                            : 0}
                          °
                        </LeaderboardPosition>
                        {/*<UpArrow>*/}
                      </LeaderboardPositionWrapper>
                      <AssociationsNumber>
                        {leaderboard.locations !== "N\\D"
                          ? leaderboard.locations.total
                          : 0}
                      </AssociationsNumber>
                      <RegisteredAssociationsWrapper>
                        <ProfilePlus />
                        <RegisteredAssociationsText>
                          Associazioni Registrate
                        </RegisteredAssociationsText>
                      </RegisteredAssociationsWrapper>
                    </Leaderboard>
                    <Leaderboard style={{ marginRight: 15 }}>
                      <LeaderboardPositionWrapper>
                        <LeaderboardPosition>
                          Pos in classifica{" "}
                          {leaderboard.events !== "N\\D"
                            ? leaderboard.events.position
                            : 0}
                          °
                        </LeaderboardPosition>
                        {/*<DownArrow>*/}
                      </LeaderboardPositionWrapper>
                      <ManifestationsNumber>
                        {leaderboard.events !== "N\\D"
                          ? leaderboard.events.total
                          : 0}
                      </ManifestationsNumber>
                      <RegisteredManifestationsWrapper>
                        <MegaphoneIcon />
                        <RegisteredManifestationsText>
                          Manifestazioni Effettuate
                        </RegisteredManifestationsText>
                      </RegisteredManifestationsWrapper>
                    </Leaderboard>
                  </LeaderboardWrapper>
                  <SwitchWrapper>
                    <SwitchText>MENSILE</SwitchText>
                    <div style={{ marginLeft: 6, marginRight: 6 }}>
                      <Switch
                        checked={leaderboardType === "year"}
                        offColor={"#B40000"}
                        onColor={"#B40000"}
                        height={25}
                        width={43}
                        handleDiameter={25}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onChange={(type) => changeLeaderboard(type)}
                        activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      />
                    </div>
                    <SwitchText>ANNUALE</SwitchText>
                  </SwitchWrapper>
                </GeneralWrapper>
              </>
            }
          ></Container>
          <Container
            title={"Membri"}
            content={
              <>
                {profile.user.role === "tutor" ? (
                  <>
                    {members && members.tutor_user && (
                      <>
                        <div style={{ marginBottom: 12 }}>
                          <NextDivisor />
                        </div>
                        <div onClick={() => visualizeOne(members.tutor_user.id)} style={{cursor: 'pointer'}}>
                          <UserRow>
                            <div
                              style={{
                                width: 35,
                                height: 35,
                                borderRadius: 100,
                                borderWidth: 2,
                                borderStyle: "solid",
                                borderColor: "#b40000",
                                backgroundImage: `url(${
                                  "https://admin.ipoh.app/" +
                                  members.tutor_user.user_pic_url
                                  })`,
                                backgroundSize: "cover",
                              }}
                            />
                            <UsernameMember>
                              {members.tutor_user.name}
                            </UsernameMember>
                            <IconColumn>
                              <ProfilePlusLittle />
                              <Number>
                                {members.tutor_user.location_leaderboard.total}
                              </Number>
                            </IconColumn>
                            <IconColumn>
                              <MegaphoneIconLittle />
                              <Number>
                                {members.tutor_user.event_leaderboard.total}
                              </Number>
                            </IconColumn>
                          </UserRow>
                        </div>
                      </>
                    )}
                    {members && members.organizers.length > 0 && (
                      <>
                        <div style={{ marginBottom: 12 }}>
                          <NextDivisor />
                        </div>
                        <div onClick={() => visualizeOne(members.organizers[0].id)} style={{cursor: 'pointer'}}>
                          <UserRow>
                            <div
                              style={{
                                width: 35,
                                height: 35,
                                borderRadius: 100,
                                borderWidth: 2,
                                borderStyle: "solid",
                                borderColor: "#b40000",
                                backgroundImage: `url(${
                                  "https://admin.ipoh.app/" +
                                  members.organizers[0].user_pic_url
                                  })`,
                                backgroundSize: "cover",
                              }}
                            />
                            <UsernameMember>
                              {members.organizers[0].name}
                            </UsernameMember>
                            <IconColumn>
                              <ProfilePlusLittle />
                              <Number>
                                {members.organizers[0].location_leaderboard.total}
                              </Number>
                            </IconColumn>
                            <IconColumn>
                              <MegaphoneIconLittle />
                              <Number>
                                {members.organizers[0].event_leaderboard.total}
                              </Number>
                            </IconColumn>
                          </UserRow>
                        </div>
                        <div style={{ marginBottom: 12 }}>
                          <NextDivisor />
                        </div>
                        <div onClick={() => visualizeOne(members.organizers[1].id)} style={{cursor: 'pointer'}}>
                          <UserRow>
                            <div
                              style={{
                                width: 35,
                                height: 35,
                                borderRadius: 100,
                                borderWidth: 2,
                                borderStyle: "solid",
                                borderColor: "#b40000",
                                backgroundImage: `url(${
                                  "https://admin.ipoh.app/" +
                                  members.organizers[1].user_pic_url
                                  })`,
                                backgroundSize: "cover",
                              }}
                            />
                            <UsernameMember>
                              {members.organizers[1].name}
                            </UsernameMember>
                            <IconColumn>
                              <ProfilePlusLittle />
                              <Number>
                                {members.organizers[1].location_leaderboard.total}
                              </Number>
                            </IconColumn>
                            <IconColumn>
                              <MegaphoneIconLittle />
                              <Number>
                                {members.organizers[1].event_leaderboard.total}
                              </Number>
                            </IconColumn>
                          </UserRow>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                    profile.user.role === "manager" && (
                      <>
                        {members && members.manager_user && (
                          <>
                            <div onClick={() => visualizeOne(members.manager_user.id)} style={{cursor: 'pointer'}}>
                              <UserRow>
                                <div
                                  style={{
                                    width: 35,
                                    height: 35,
                                    borderRadius: 100,
                                    borderWidth: 2,
                                    borderStyle: "solid",
                                    borderColor: "#b40000",
                                    backgroundImage: `url(${
                                      "https://admin.ipoh.app/" +
                                      members.manager_user.user_pic_url
                                      })`,
                                    backgroundSize: "cover",
                                  }}
                                />
                                <UsernameMember>
                                  {members.manager_user.name}
                                </UsernameMember>
                                <IconColumn>
                                  <ProfilePlusLittle />
                                  <Number>
                                    {
                                      members.manager_user.location_leaderboard
                                        .total
                                    }
                                  </Number>
                                </IconColumn>
                                <IconColumn>
                                  <MegaphoneIconLittle />
                                  <Number>
                                    {members.manager_user.event_leaderboard.total}
                                  </Number>
                                </IconColumn>
                              </UserRow>
                            </div>
                          </>
                        )}
                        {members && members.tutors.length > 0 && (
                          <>
                            <div style={{ marginBottom: 12 }}>
                              <NextDivisor />
                            </div>
                            <div onClick={() => visualizeOne(members.tutors[0].id)} style={{cursor: 'pointer'}}>
                              <UserRow>
                                <div
                                  style={{
                                    width: 35,
                                    height: 35,
                                    borderRadius: 100,
                                    borderWidth: 2,
                                    borderStyle: "solid",
                                    borderColor: "#b40000",
                                    backgroundImage: `url(${
                                      "https://admin.ipoh.app/" +
                                      members.tutors[0].user_pic_url
                                      })`,
                                    backgroundSize: "cover",
                                  }}
                                />
                                <UsernameMember>
                                  {members.tutors[0].name}
                                </UsernameMember>
                                <IconColumn>
                                  <ProfilePlusLittle />
                                  <Number>
                                    {members.tutors[0].location_leaderboard.total}
                                  </Number>
                                </IconColumn>
                                <IconColumn>
                                  <MegaphoneIconLittle />
                                  <Number>
                                    {members.tutors[0].event_leaderboard.total}
                                  </Number>
                                </IconColumn>
                              </UserRow>
                            </div>
                          </>
                        )}
                        {members && members.organizers.length > 0 && (
                          <>
                            <div style={{ marginBottom: 12 }}>
                              <NextDivisor />
                            </div>
                            <div onClick={() => visualizeOne(members.organizers[0].id)} style={{cursor: 'pointer'}}>
                              <UserRow>
                                <div
                                  style={{
                                    width: 35,
                                    height: 35,
                                    borderRadius: 100,
                                    borderWidth: 2,
                                    borderStyle: "solid",
                                    borderColor: "#b40000",
                                    backgroundImage: `url(${
                                      "https://admin.ipoh.app/" +
                                      members.organizers[0].user_pic_url
                                      })`,
                                    backgroundSize: "cover",
                                  }}
                                />
                                <UsernameMember>
                                  {members.organizers[0].name}
                                </UsernameMember>
                                <IconColumn>
                                  <ProfilePlusLittle />
                                  <Number>
                                    {
                                      members.organizers[0].location_leaderboard
                                        .total
                                    }
                                  </Number>
                                </IconColumn>
                                <IconColumn>
                                  <MegaphoneIconLittle />
                                  <Number>
                                    {members.organizers[0].event_leaderboard.total}
                                  </Number>
                                </IconColumn>
                              </UserRow>
                            </div>
                          </>
                        )}
                      </>
                    )
                  )}
                <ShowAllWrapper
                  style={{ marginTop: 5 }}
                  onClick={() => visualizeAll(profile.user.role)}
                >
                  <GradientButton text={"Visualizza tutti"} />
                </ShowAllWrapper>
              </>
            }
          ></Container>
          <Container
            title={"Statistiche Team"}
            isStats
            type={chartType}
            setType={changeChart}
            content={
              <>
                <Chart options={options} />
              </>
            }
          ></Container>
        </Body>
      </>
    );
  } else
    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginTop: "20%",
        }}
      >
        <Loader type="Oval" color="#B40000" height={100} width={100} />
      </div>
    );
}

export default Team;
