import React from "react";
import { ReactComponent as Divisor } from "../../assets/divisor.svg";
import { ReactComponent as RedLens } from "../../assets/red-lens.svg";
import { ReactComponent as ThreeDots } from "../../assets/message-three-points.svg";
import { ReactComponent as BackFirst } from "../../assets/back-first-page.svg";
import { ReactComponent as NextLast } from "../../assets/next-last-page.svg";
import { ReactComponent as BackRed } from "../../assets/back-red.svg";
import { ReactComponent as NextRed } from "../../assets/next-red.svg";
import styled from "styled-components";

export const NextDivisor = () => {
  return (
    <div style={{ marginBottom: 11 }}>
      <Divisor width={240} height={1} />
    </div>
  );
};

export const Lens = () => {
  return (
    <div style={{ position: "absolute", top: 9, right: 13 }}>
      <RedLens width={20} height={20} />
    </div>
  );
};

export const RedDots = () => {
  return (
    <div style={{ position: "absolute", top: 5, right: -10, cursor: 'pointer' }}>
      <ThreeDots width={22} height={22} />
    </div>
  );
};

export const BackToFirst = () => {
  return (
    <div>
      <BackFirst width={14} height={16} />
    </div>
  );
};

export const NextToLast = () => {
  return (
    <div>
      <NextLast width={14} height={16} />
    </div>
  );
};

export const Back = () => {
  return (
    <div style={{ marginRight: 10, marginLeft: 10 }}>
      <BackRed width={14} height={16} />
    </div>
  );
};

export const Next = () => {
  return (
    <div style={{ marginRight: 10, marginLeft: 10 }}>
      <NextRed width={14} height={16} />
    </div>
  );
};

export const ErrorMessage = styled.p`
  color: #b40000;
  margin-bottom: 12px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 13px;
  margin-bottom: 23px;
  width: 50%;
  @media (min-width: 768px) {
    width: 30%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  background-color: #fff;
  width: auto;
  height: auto;
  padding-top: 22px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  padding-top: 18px;
  background-color: #F2F3F8;
  align-items: center;
`;

export const NumberDiv = styled.div`
  background-color: ${props => (props.isActive ? "#DFE3E7" : "none")};
  width: 12px;
  height: 14px;
  text-align: center;
`;

export const SearchWrapper = styled.div`
  width: 264px;
  height: 38px;
  border-width: 1px;
  border-style: solid;
  border-color: #b40000;
  position: relative;
  margin-bottom: 53px;
`;

export const InputText = styled.input.attrs(props => ({
  type: "text"
}))`
  width: 85%;
  height: 38px;
  color: #808080;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  padding-left: 11px;
  box-sizing: border-box;
  border: none;
  :focus {
    outline: none;
  }
`;

export const AssociationWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 10px;
`;

export const AssociationName = styled.p`
  font-size: 14px;
  color: black;
  width: 220px;
  margin-bottom: 2px;
`;

export const AssociationPlace = styled.p`
  font-size: 12px;
  color: #808080;
  margin-bottom: 8px;
`;

export const AddButton = styled.button`
  display: block;
  margin: 0 auto 30px auto;
  padding: 12px 10px;
  border-radius: 6px;
  color: #FFFFFF;
  background: rgb(218,0,0);
  background: linear-gradient(90deg, rgba(218,0,0,1) 0%, rgba(159,0,0,1) 100%);
  border: none;
`;
