import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {ReactComponent as CheckMark} from '../../assets/completed-checkmark.svg';

import {
  ContentWrapper,
  Title,
  XPMessage,
  Message
} from './completed-notification.style';

function CompletedNotification() {
  const history = useHistory();
  const { type } = useParams();
  let data;

  switch (type) {
    case 'manifestation': 
      data = {
        title: 'MANIFESTAZIONE EFFETTUATA',
        xp: 30,
        messageRows: [
          'CONTINUA COSÌ !',
          'Non dimenticare di scaricare',
          'ed inviare il modulo'
        ]
      };
      break;
    default: history.goBack();
  }

  return (
    <ContentWrapper onClick={() => history.goBack()}>
      <Title>{data.title}</Title>
      <CheckMark />
      <XPMessage style={{ fontSize: 22, marginTop: 28 }}>CONGRATULAZIONI</XPMessage>
      <XPMessage style={{ marginBottom: 49 }}>+ {data.xp} XP</XPMessage>
      {data.messageRows.map((m, i) => <Message key={i}>{m}</Message>)}
      <p style={{ marginTop: 51 }}>Tocca per continuare</p>
    </ContentWrapper>
  );
}

export default CompletedNotification;
