import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../assets/ipoh-logo.svg";
import { ReactComponent as Guy } from "../assets/menu-profile.svg";
import { ReactComponent as Pencil } from "../assets/menu-edit.svg";
import { ReactComponent as Dots } from "../assets/menu-calendar.svg";
import { ReactComponent as Bell } from "../assets/menu-bell.svg";
import { ReactComponent as Cup } from "../assets/menu-cup.svg";
import { ReactComponent as Team } from "../assets/menu-team.svg";
import { ReactComponent as Cross } from "../assets/close-side.svg";
import { ReactComponent as WhiteLogo } from "../assets/transparent-logo.svg";
import { ReactComponent as Door } from "../assets/logout.svg";

export const TransparentLogo = () => {
  return (
    <div style={{ position: "absolute", top: 60, right: -45, opacity: 0.2}}>
      <WhiteLogo width={145} height={315} />
    </div>
  );
};

export const Close = () => {
  return <Cross width={14} height={14} />;
};

export const Profile = () => {
  return <Guy width={20} height={20} />;
};

export const Edit = () => {
  return <Pencil width={20} height={20} />;
};

export const Calendar = () => {
  return <Dots width={20} height={20} />;
};

export const Credits = () => {
  return <Bell width={20} height={20} />;
};

export const Leaderboard = () => {
  return <Cup width={20} height={20} />;
};

export const MyTeam = () => {
  return <Team width={20} height={20} />;
};

export const Logout = () => {
  return <Door width={20} height={20} />;
};

export const IpohLogo = () => {
  return <Logo width={96} height={39} />;
};

export const SideMenuContainer = styled.div`
  display: flex;
  max-width: 252px;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  border-bottom: 0.25px solid #cccccc;
  padding-top: 25px;
  position: relative;
  overflow-x: hidden;
`;

export const SecondMenu = styled.div`
  display: flex;
  max-width: 252px;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  padding-top: 31px;
`;

export const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 51px;
  margin-top: 28px;
`;

export const RedRectangle = styled.div`
  width: 5px;
  height: 33px;
  background-color: #b40000;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 37px;
  margin-left: 20%;
  width: auto;
`;

export const SideMenuCategory = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  margin-bottom: 29px;
  padding-left: 22px;
  width: 164px;
`;

export const SideMenuText = styled.p`
  padding-left: 10px;
  font-size: 17px;
  color: black;

  &:hover{
  cursor: pointer;
  }
`;

export const AddText = styled.p`
  font-size: 17px;
  color: #b40000;
  margin-bottom: 20px;

  &:hover{
  cursor: pointer;
  }
`;

export const CloseMenu = styled.div`
  padding: 15px 0;
  margin-right: 32px;
  cursor: pointer;
`;
