import React from "react";
import {
  Medal,
  MedalsWrapper,
  MedalsCount,
  Level,
  NoMedal,
} from "../../screens/home/home.style";

function Medals(props) {
  const { profile } = props;

  return (
    <MedalsWrapper>
      <MedalsCount>
        {profile.user.level === 1 && (
          <>
            <Medal />
            <NoMedal />
            <NoMedal />
            <NoMedal />
            <NoMedal />
            <NoMedal />
          </>
        )}
        {profile.user.level === 2 && (
          <>
            <Medal />
            <Medal />
            <NoMedal />
            <NoMedal />
            <NoMedal />
            <NoMedal />
          </>
        )}
        {profile.user.level === 3 && (
          <>
            <Medal />
            <Medal />
            <Medal />
            <NoMedal />
            <NoMedal />
            <NoMedal />
          </>
        )}
        {profile.user.level === 4 && (
          <>
            <Medal />
            <Medal />
            <Medal />
            <Medal />
            <NoMedal />
            <NoMedal />
          </>
        )}
        {profile.user.level === 5 && (
          <>
            <Medal />
            <Medal />
            <Medal />
            <Medal />
            <Medal />
            <NoMedal />
          </>
        )}
        {profile.user.level === 6 && (
          <>
            <Medal />
            <Medal />
            <Medal />
            <Medal />
            <Medal />
            <Medal />
          </>
        )}
      </MedalsCount>
      {profile.user.level === 1 && <Level>PRINCIPIANTE</Level>}
      {profile.user.level === 2 && <Level>AMATORE</Level>}
      {profile.user.level === 3 && <Level>ESPERTO</Level>}
      {profile.user.level === 4 && <Level>VETERANO</Level>}
      {profile.user.level === 5 && <Level>LEADER</Level>}
      {profile.user.level === 6 && <Level>COMANDANTE</Level>}
    </MedalsWrapper>
  );
}

export default Medals;
