import React, { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from "react-router-dom";

import AppContext from '../../services/context.service';

import { ReactComponent as LogoSvg } from '../../assets/logo.svg';
import {
  Conteiner,
  Row,
  Text,
  TextInput,
  Checkbox,
  SubmitButton,
  LogoWrapper,
  ErrorText
} from './login.style';

function LoginScreen() {
  const { login } = useContext(AppContext);
  const history = useHistory();
  const { register, handleSubmit, control, errors, setError } = useForm({
    defaultValues: {
      email: '',
      password: '',
      stayConnected: 0,
      termsAndConditions: 1
    }
  });

  async function onSubmit({ email, password, stayConnected, termsAndConditions }) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    if (!termsAndConditions) {
      return setError('mainError', 'authorization', 'Accetta i termini e le condizioni prima di continuare');
    }

    try {
      await login(email, password, stayConnected);
    } catch (e) {
      const { response } = e;
      if (!response) {
        return setError('mainError', 'unknown', 'Impossibile eseguire il login, riprova più tardi');
      }

      switch (response.status) {
        case 404: setError('email', '404', 'Utente inesistente'); break;
        case 422: setError('password', '422', 'Password non valida'); break;
        default: break;
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LogoWrapper><LogoSvg /></LogoWrapper>
      <Conteiner>
        <Text fontSize='27' color='#1A1A1A'>Benvenuto</Text>
        <Text margin='10px 0 5px 0' style={{ textAlign: 'center' }}>Accedi con le credenziali che ti sono state inviate</Text>

        <ErrorText>{errors.mainError && errors.mainError.message}</ErrorText>

        <TextInput
          name="email"
          autoComplete="on"
          error={errors.email}
          label="Indirizzo email"
          ref={register({ required: true })}
        />
        <TextInput
          name="password"
          autoComplete="on"
          error={errors.password}
          type="password"
          label="Password"
          ref={register({ required: true })}
        />

        <Row margin='0 0 16px 0'>
          <Controller
            name="stayConnected"
            as={<Checkbox renderLabel={() => <Text color='#808080'>Resta connesso</Text>} />}
            control={control}
          />
          <div style={{cursor: 'pointer'}} onClick={() => history.push({ pathname: "/recover" })}><Text>Recupera la password</Text></div>
        </Row>

        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <Controller
            name="termsAndConditions"
            control={control}
            as={<Checkbox
              renderLabel={() => (
                <Text>
                  <span style={{ color: '#808080' }}>Ho letto ed accetto </span>
                  Termini e Condizioni
                  <span style={{ color: '#808080' }}> e </span>
                  Privacy policy
                </Text>
              )}
            />}
          />
        </div>
        <SubmitButton>Accedi</SubmitButton>
      </Conteiner>
    </form>
  );
}

export default LoginScreen;
