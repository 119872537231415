import React from 'react';
import styled, { css } from 'styled-components';

import {ReactComponent as ArrowDownSvg} from '../../assets/select-arrow-down.svg';
import {ReactComponent as ArrowUpSvg} from '../../assets/select-arrow-up.svg';

function NumberInput({ value: num, onChange=()=>{}, highlightError, onlyPositive, digitLimit=2, step=1, min, max }) {

  function onValueChange({ target: { value } }) {
    if (onlyPositive && value[0] === '-') return;
    if (value.length > digitLimit || isNaN(value)) return;
    if (value === num) return;

    onChange(value);
  }

  function onArrowUp() {
    let value = +num +step;
    if (max) value = Math.min(max, value);

    if (+value === +num) return;
    onValueChange({ target: { value: ''+value } });
  }

  function onArrowDown() {
    if (onlyPositive && num === '') return onValueChange({ target: { value: '0' } });
    let value = +num -step;
    if (onlyPositive) value = Math.max(0, value);
    if (min) value = Math.max(min, value);

    if (+value === +num) return;
    onValueChange({ target: { value: ''+value } });
  }

  return (
    <InputsWrapper>
      <ArrowUp onClick={onArrowUp} />
      <TextInput
        style={highlightError ? { backgroundColor: '#E5CACA', borderColor: '#B40000' } : {}}
        value={num}
        step="1"
        onChange={onValueChange} 
      />
      <ArrowDown onClick={onArrowDown} />
    </InputsWrapper>
  );
}

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextInput = styled.input.attrs({
  type: 'number'
})`
  font-size: 14px;
  width: 36px;
  height: 36px;
  border: solid #808080 1px;
  border-radius: 4px;
  text-align: center;
  color: #000000;

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } 
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } 
`;

const ArrowDown = (props) => (
  <ArrowButton {...props} down><ArrowDownSvg /></ArrowButton>
);

const ArrowUp = (props) => (
  <ArrowButton {...props} up><ArrowUpSvg /></ArrowButton>
);

const ArrowButton = styled.button.attrs({
  type: 'button'
})`
  ${p => p.up && css`
    padding-bottom: 9px;
  `}
  ${p => p.down && css`
    padding-top: 9px;
  `}
  border: none;
  background-color: transparent;
`;

export default NumberInput;
