/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import Container from "../../components/container.component";
import GoBack from "../../components/goback.component";
import Slider from "../../components/carousel.component";
import GradientButton from "../../components/gradient.button";
import Api from "../../services/api.service";
import AppContext from "../../services/context.service";
import Loader from "react-loader-spinner";
import moment from "moment";
import imageCompression from "browser-image-compression";
import {
  Wrapper,
  Body,
  AssociationName,
  AssociationPlace,
  InformationRow,
  Column,
  BlackText,
  GrayText,
  RedCall,
  RedRectangle,
  Row,
  ShortText,
  DescriptionText,
  Wrapper2,
  GradientWrapper,
  LoadFile,
  Popup,
  PopupButtonWrapper,
  Overlay,
  PopupTextWrapper,
  Column2,
} from "./association.style";
import { useHistory } from "react-router-dom";

function Association(props) {
  const { openManifestationModal } = useContext(AppContext);
  const [result, setResult] = useState();
  const [profile, setProfile] = useState();
  const [popup, setPopup] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();
  const changePic = useRef();

  const getLocation = async (id) => {
    try {
      const res = await Api.getProfile();
      setProfile(res.data);
      const res2 = await Api.getLocation(id);
      setResult(res2.data);
    } catch (e) {
      // console.log(e.message);
    }
  };

  function onAdd() {
    openManifestationModal({
      create: true,
      date: moment(),
      id: props.location.state,
      label: result.name,
    });
  }

  useEffect(() => {
    getLocation(props.location.state);
  }, []);

  const addManifestation = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    onAdd();
  };

  const closePopup = async () => {
    setPopup(false);
    const res = await Api.getLocation(props.location.state);
    setResult(res.data);
  };

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 640,
    useWebWorker: true,
  };

  const loadFile = async (e) => {
    try {
      const form = new FormData();
      for (let i = 0; i < e.length; i++) {
        const compressedFile = await imageCompression(e[i], options);
        form.append("photos[" + i + "]", compressedFile);
      }
      await Api.updateLocation(form, props.location.state);
      setPopup(true);
    } catch (e) {
      setError(true);
      //console.debug(e.message);
    }
  };

  if (result) {
    return (
      <>
        <GoBack />
        <Body>
          {popup && (
            <>
              <Overlay />
              <Popup>
                <PopupTextWrapper>
                  {error ? (
                    <BlackText>
                      Qualcosa è andato storto. Ritenta l'upload
                    </BlackText>
                  ) : (
                    <BlackText>Upload effettuato con successo!</BlackText>
                  )}
                </PopupTextWrapper>
                <PopupButtonWrapper onClick={() => closePopup()}>
                  <GradientButton text={"Ok!"} />
                </PopupButtonWrapper>
              </Popup>
            </>
          )}
          <Container
            title={"Scheda Associazione"}
            content={
              <>
                <LoadFile
                  id="file"
                  type="file"
                  accept=".jpg, .png"
                  onChange={(e) => loadFile(e.target.files)}
                  ref={changePic}
                  multiple
                />
                <Slider
                  photos={result.photos_url}
                  changePic={changePic}
                  id={profile.user.id}
                  location_id={result.created_by_id}
                />
                <Wrapper>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <AssociationName>{result.name}</AssociationName>
                    {result.place && <AssociationPlace>{result.place?.type === 'local' ? result.place.city : result.place.comune}</AssociationPlace>}
                  </div>
                  <InformationRow>
                    <Column2>
                      <BlackText>Referente</BlackText>
                      {result.referents?.[0] ? <GrayText style={{ width: 90 }}>
                        {`${result.referents?.[0].firstName} ${result.referents?.[0].lastName}`}
                      </GrayText> : <GrayText>Nessun referente</GrayText>}
                    </Column2>
                    <Column2>
                      <BlackText>Registrata il</BlackText>
                      <GrayText>
                        {moment(result.created_at).format("ll").toUpperCase()}
                      </GrayText>
                    </Column2>
                    <Column2>
                      {result.referents?.[0] ? <a
                        style={{ textDecoration: "none" }}
                        href={"tel:" + result.referents?.[0].phone_1}
                      >
                        <RedCall />
                        <GrayText>Contatta</GrayText>
                      </a> : <GrayText>Nessun contatto</GrayText>}
                    </Column2>
                  </InformationRow>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <RedRectangle />
                  </div>
                  <Wrapper2>
                    <Row>
                      <Column>
                        <ShortText>Via</ShortText>
                        <DescriptionText>{result.road}</DescriptionText>
                      </Column>
                      <Column>
                        <ShortText>CAP</ShortText>
                        <DescriptionText>{result.place?.cap}</DescriptionText>
                      </Column>
                    </Row>
                    <Row>
                      
                      <Column>
                        <ShortText>Provincia</ShortText>
                        {result.place && <DescriptionText>
                          {result.place?.type === 'local' ? result.place.province : result.place.provincia}
                        </DescriptionText>}
                      </Column>
                      <Column>
                        <ShortText>Telefono 1</ShortText>
                        <a
                          style={{ textDecoration: "none" }}
                          href={"tel:" + result.referents?.[0].phone_1}
                        >
                          <DescriptionText>
                            {result.referents?.[0].phone_1}
                          </DescriptionText>
                        </a>
                      </Column>
                    </Row>
                    <Row>
                    <Column style={{width: 140}}>
                        <ShortText>E-mail</ShortText>
                        
                        <a
                          style={{ textDecoration: "none", }}
                          href={"mailto:" + result.referents?.[0].email}
                        >
                          <DescriptionText>
                            {result.referents?.[0].email}
                          </DescriptionText>
                        </a>
                      </Column>
                      <Column>
                        <ShortText>Telefono 2</ShortText>
                        {result.referents?.[0].phone_2 && (
                          <a
                            style={{ textDecoration: "none" }}
                            href={"tel:" + result.referents?.[0].phone_2}
                          >
                            <DescriptionText>
                              {result.referents?.[0].phon_2}
                            </DescriptionText>
                          </a>
                        )}
                      </Column>
                      
                    </Row>
                    <Row>
                      <Column>
                        <ShortText>Costo</ShortText>
                        <DescriptionText>
                          {result.additional_infos.cost}
                        </DescriptionText>
                      </Column>
                      <Column>
                        <ShortText>Feedback</ShortText>
                        <DescriptionText>
                          {result.additional_infos.feedback}
                        </DescriptionText>
                      </Column>
                    </Row>
                    <Row>
                      
                      <Column>
                        <ShortText>Idea</ShortText>
                        <DescriptionText>
                          {result.additional_infos.ideas}
                        </DescriptionText>
                      </Column>
                      <Column>
                        <ShortText>Tempo</ShortText>
                        {result.additional_infos.time && <DescriptionText>
                          {result.additional_infos.time} mesi
                        </DescriptionText>}
                      </Column>
                    </Row>
                    <Row>
                      <Column>
                        <ShortText>Note</ShortText>
                        <DescriptionText>{result.note}</DescriptionText>
                      </Column>
                    </Row>
                    {result.referents?.length > 1 &&
                      result.referents.map((referent, key) => {
                        if (key > 0) {
                          return (
                            <>
                              <Row>
                                <Column>
                                  <ShortText>
                                    Nome referente {key + 1}
                                  </ShortText>
                                  <DescriptionText>
                                    {referent.name}
                                  </DescriptionText>
                                </Column>
                                <Column>
                                  <ShortText>
                                    E-mail referente {key + 1}
                                  </ShortText>
                                  <DescriptionText>
                                    {referent.email}
                                  </DescriptionText>
                                </Column>
                              </Row>
                              <Row>
                                <Column>
                                  <ShortText>
                                    Telefono 1 referente {key + 1}
                                  </ShortText>
                                  <DescriptionText>
                                    {referent.phone_1}
                                  </DescriptionText>
                                </Column>
                                <Column>
                                  <ShortText>
                                  Telefono 2 referente {key + 1}
                                  </ShortText>
                                  <DescriptionText>
                                    {referent.phone_2}
                                  </DescriptionText>
                                </Column>
                              </Row>
                            </>
                          );
                        }
                      })}
                  </Wrapper2>
                </Wrapper>
              </>
            }
          ></Container>
          <div style={{ display: 'flex' }}>
            <GradientWrapper onClick={() => history.push(`/editassociation/${result.id}`)} style={{ marginRight: '15px'}}>
              <GradientButton text={"Modifica Associazione"} />
            </GradientWrapper>
            {result.created_by_id === profile.user.id && (
              <GradientWrapper onClick={() => addManifestation()}>
                <GradientButton text={"Aggiungi Manifestazione"} />
              </GradientWrapper>
            )}
          </div>
        </Body>
      </>
    );
  } else
    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginTop: "20%",
        }}
      >
        <Loader type="Oval" color="#B40000" height={100} width={100} />
      </div>
    );
}

export default Association;
