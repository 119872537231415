import { ReactComponent as Bolt } from "../assets/bolt.svg";
import { ReactComponent as Cafe } from "../assets/cafe.svg";
import { ReactComponent as Hundred } from "../assets/hundred.svg";
import { ReactComponent as OneAssociation } from "../assets/1_association.svg";
import { ReactComponent as TenAssociations } from "../assets/10_associations.svg";
import { ReactComponent as FiftyAssociations } from "../assets/50_associations.svg";
import { ReactComponent as Flag } from "../assets/flag.svg";
import { ReactComponent as Senior } from "../assets/senior.svg";
import { ReactComponent as King } from "../assets/king.svg";
import { ReactComponent as Teamwork } from "../assets/teamwork.svg";
import { ReactComponent as TeamworkOn } from "../assets/teamwork-on.svg";
import { ReactComponent as Engineer } from "../assets/engineer.svg";
import { ReactComponent as EngineerOn } from "../assets/engineer-on.svg";
import { ReactComponent as Golfman } from "../assets/golfman.svg";
import { ReactComponent as GolfmanOn } from "../assets/golfman-on.svg";
import { ReactComponent as FirstPlaceAssociation } from "../assets/first-place-association.svg";
import { ReactComponent as SecondPlaceManifestation } from "../assets/second-place-manifestation.svg";
export default {
    bolt: Bolt,
    cafe: Cafe,
    hundred: Hundred,
    one_association: OneAssociation,
    ten_associations: TenAssociations,
    fifty_associations: FiftyAssociations,
    flag: Flag,
    senior: Senior,
    king: King,
    teamwork: Teamwork,
    teamworkOn: TeamworkOn,
    engineer: Engineer,
    engineerOn: EngineerOn,
    golfman: Golfman,
    golfmanOn: GolfmanOn,
    firstplaceassociation: FirstPlaceAssociation,
    secondplacemanifestation: SecondPlaceManifestation
  };
  