class Storage {
  static _storage = window.localStorage;

  static setItem = (key, item) => this._storage.setItem(key, item);
  static removeItem = (key) => this._storage.removeItem(key);
  static removeAll = () => this._storage.clear();
  static getItem = (key) => this._storage.getItem(key);

  // public methods
  // bearer token
  static getToken = () => this.getItem('token');
  static setToken = (token) => this.setItem('token', token);
  static removeToken = () => this.removeItem('token');
}

export default Storage;
