import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Container from "../../components/container.component";
import Api from "../../services/api.service";
import GoBack from "../../components/goback.component";
import Loader from "react-loader-spinner";
import {
  Body,
  NextDivisor,
  UserRow,
  IconColumn,
  ProfilePlusLittle,
  MegaphoneIconLittle,
  UsernameMember,
  Number,
} from "../home/home.style";

const MemberCard = ({ member, onClick, separator }) => 
(<>
{separator && <div style={{ marginBottom: 12 }}>
  <NextDivisor />
</div>}
<div onClick={onClick} style={{ cursor: 'pointer' }}>
<UserRow>
  <div
    style={{
      width: 35,
      height: 35,
      borderRadius: 100,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "#b40000",
      backgroundImage: `url(${
        "https://admin.ipoh.app/" +
        member.user_pic_url
        })`,
      backgroundSize: "cover",
    }}
  />
  <UsernameMember>
    {member.name}
  </UsernameMember>
  <IconColumn>
    <ProfilePlusLittle />
    <Number>
      {member.location_leaderboard.total}
    </Number>
  </IconColumn>
  <IconColumn>
    <MegaphoneIconLittle />
    <Number>
      {member.event_leaderboard.total}
    </Number>
  </IconColumn>
</UserRow>
</div>
</>);

function ListMembers(props) {
  const history = useHistory();
  const [members, setMembers] = useState();

  const getTeamMembers = async () => {
    try {
      const res = await Api.listUsers(true);
      setMembers(res.data);
    } catch (e) {
      // console.log(e.message);
    }
  };

  useEffect(() => {
    getTeamMembers();
  }, []);

  const visualizeOne = (id) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    history.push({ pathname: "/profile", state: id });
  };

  if (members) {
    return (
      <>
        <GoBack />
        <Body>
          <Container
            title={"Il mio team"}
            content={
              <div style={{ marginTop: 36, marginBottom: 37 }}>
                <>
                  {props.location.state === "manager" ? (
                    <>
                      <MemberCard member={members.manager_user} onClick={() => visualizeOne(members.manager_user.id)} />
                      {['managers', 'tutors', 'organizers'].map((type) =>
                        Object.values(members[type]).map((member) => <MemberCard member={member} onClick={() => visualizeOne(member.id)} separator={true} />))}
                    </>
                  ) : (
                      props.location.state === "tutor" && (
                        <>
                          <div onClick={() => visualizeOne(members.tutor_user.id)} style={{ cursor: 'pointer' }}>
                            <UserRow>
                              <div
                                style={{
                                  width: 35,
                                  height: 35,
                                  borderRadius: 100,
                                  borderWidth: 2,
                                  borderStyle: "solid",
                                  borderColor: "#b40000",
                                  backgroundImage: `url(${
                                    "https://admin.ipoh.app/" +
                                    members.tutor_user.user_pic_url
                                    })`,
                                  backgroundSize: "cover",
                                }}
                              />
                              <UsernameMember>
                                {members.tutor_user.name}
                              </UsernameMember>
                              <IconColumn>
                                <ProfilePlusLittle />
                                <Number>
                                  {members.tutor_user.location_leaderboard.total}
                                </Number>
                              </IconColumn>
                              <IconColumn>
                                <MegaphoneIconLittle />
                                <Number>
                                  {members.tutor_user.event_leaderboard.total}
                                </Number>
                              </IconColumn>
                            </UserRow>
                          </div>
                          {members.organizers &&
                            Object.keys(members.organizers).map((organizer) => {
                              return (
                                <>
                                  <div style={{ marginBottom: 12 }}>
                                    <NextDivisor />
                                  </div>
                                  <div onClick={() => visualizeOne(members.organizers[organizer].id)} style={{ cursor: 'pointer' }}>
                                    <UserRow>
                                      <div
                                        style={{
                                          width: 35,
                                          height: 35,
                                          borderRadius: 100,
                                          borderWidth: 2,
                                          borderStyle: "solid",
                                          borderColor: "#b40000",
                                          backgroundImage: `url(${
                                            "https://admin.ipoh.app/" +
                                            members.organizers[organizer]
                                              .user_pic_url
                                            })`,
                                          backgroundSize: "cover",
                                        }}
                                      />
                                      <UsernameMember>
                                        {members.organizers[organizer].name}
                                      </UsernameMember>
                                      <IconColumn>
                                        <ProfilePlusLittle />
                                        <Number>
                                          {
                                            members.organizers[organizer]
                                              .location_leaderboard.total
                                          }
                                        </Number>
                                      </IconColumn>
                                      <IconColumn>
                                        <MegaphoneIconLittle />
                                        <Number>
                                          {
                                            members.organizers[organizer]
                                              .event_leaderboard.total
                                          }
                                        </Number>
                                      </IconColumn>
                                    </UserRow>
                                  </div>
                                </>
                              );
                            })}
                        </>
                      )
                    )}
                </>
              </div>
            }
          ></Container>
        </Body>
      </>
    );
  } else
    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginTop: "20%",
        }}
      >
        <Loader type="Oval" color="#B40000" height={100} width={100} />
      </div>
    );
}

export default ListMembers;
