import React from "react";
import { useHistory } from "react-router-dom";
import {
  GoBack,
  GoBackWrapper,
  GoBackText,
  BackArrow
} from "./goback.component.style";

function GoBackComponent(props) {
  const { step, previous } = props;
  const history = useHistory();

  if (step) {
    return (
      <GoBack onClick={() => previous()}>
        <GoBackWrapper>
          <BackArrow />
          <GoBackText>INDIETRO</GoBackText>
        </GoBackWrapper>
      </GoBack>
    );
  } else {
    return (
      <GoBack onClick={() => history.goBack()}>
        <GoBackWrapper>
          <BackArrow />
          <GoBackText>INDIETRO</GoBackText>
        </GoBackWrapper>
      </GoBack>
    );
  }
}

export default GoBackComponent;
