import { useState, useCallback } from 'react';
import Api from '../../services/api.service';

function useManifestation() {
  const [manifestation, setManifestation] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchManifestation = useCallback(async (id) => {
    setLoading(true);
    try {
      const { data } = await Api.getManifestation(id);
      const { data: locData } = await Api.getLocation(data.location_id);
      data.location = locData;
      if (data.state === 'draft' && data.change_date) {
        data.state = 'shifted';
      }
      setManifestation(data);
    } finally {
      setLoading(false);
    }
  }, [setManifestation]);

  return { manifestation, fetchManifestation, loading };
}

export default useManifestation;
