import React, { Fragment } from 'react';
import styled from 'styled-components';

function Modal({ visible, hideModal, children }) {
  if (!visible) return null;
  return (
    <Fragment>
      <Overlay onClick={hideModal} />
      <Content>
        {children}
      </Content>
    </Fragment>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: -70px;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 1;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
  z-index: 2;
`;

export default Modal;
