import React from "react";
import Icons from "./icons";
import styled from "styled-components";

export const GrayImage = styled.img`
  filter: grayscale(1);
  width: 82px;
  height: 82px;
`;

let Icon;

function RewardIcon(props) {
  const { icon, locked } = props;
  Icon = Icons[icon];

  if (!locked)
    return (
      <img src={`${icon}`} alt={"badge"} style={{ width: 82, height: 82 }}></img>
    );
  else
    return (
      <GrayImage src={`${icon}`} alt={"badge"}></GrayImage>
    );
}

export default RewardIcon;
