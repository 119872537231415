import React from 'react';
import { Wrapper } from './footer.style';

function Footer() {
  return (
    <Wrapper>
      <p>Ipoh App &copy; 2022</p>
      <p>hopi s.r.l.</p>
    </Wrapper>
  );
}

export default Footer;
