import React from 'react';
import styled from 'styled-components';

import NumberInput from '../numer-input';

function TimeInput({ label, value: time, highlightError, onChange=()=>{} }) {
  const [h, m] = time.split(':');

  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <Content>
        <NumberInput
          highlightError={highlightError}
          value={h}
          onChange={(value) => onChange(`${value}:${m}`)}
          onlyPositive
          isInteger
          min={10}
          max={21}
        />
        <Colons>:</Colons>
        <NumberInput
          highlightError={highlightError}
          value={m < 10 ? '0'+m : m}
          onChange={(value) => onChange(`${h}:${value}`)}
          onlyPositive
          isInteger
          step={30}
          max={30}
        />
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-size: 14px;
`;

const Label = styled.p`
  margin-bottom: 10px;
  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Colons = styled.p`
  display: inline;
  color: #000000;
  margin: 0 13px;
`;

export default TimeInput;
