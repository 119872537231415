import React from "react";
import styled from "styled-components";
import { ReactComponent as BlackArrow } from "../assets/back.svg";

export const BackArrow = () => {
    return <BlackArrow width={9} height={18} />;
  };

export const GoBack = styled.div`
  background-color: #DFE3E7;
  width: 100%;
  height: auto;
  cursor: pointer;
`;

export const GoBackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 38px;
  margin-left: 34px;
`

export const GoBackText = styled.p`
  margin-left: 15px;
  font-size: 12px;
  color: #808080;
`