import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Api from '../../services/api.service';

import GoBack from "../../components/goback.component";
import LeaderboardRows from '../../components/leaderboard-rows';

import {
  PageConteiner,
  LeaderboardConteiner,
  TitleText,
  LeaderboardSwitch,
  ROHButton
} from './leaderboard-list.style';

function LeaderboardListScreen() {
  const [leaderboardType, setLeaderboardType] = useState('month');
  const [leaderboards, setLeaderboard] = useState({ locations: [], events: [] });
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function formatInterval() {
    return moment()
      .format(leaderboardType === 'month' ? 'MMMM YYYY' : 'YYYY')
      .toUpperCase();
  }

  async function fetchLists(type) {
    const dateMin = moment().startOf(type);
    const dateMax = moment().endOf(type);
    const [{ data: locData }, { data: evtData }] = await Promise.all([
      Api.leaderboardLocations(dateMin, dateMax),
      Api.leaderboardEvents(dateMin, dateMax)
    ]);

    setLeaderboard({
      locations: locData.leaderboard || [],
      events: evtData.leaderboard || []
    })
  }

  useEffect(() => {
    setLoading(true);
    fetchLists(leaderboardType).finally(() => setLoading(false));
  }, [leaderboardType]);

  const interval = formatInterval();

  return (
    <>
      <GoBack />
      <PageConteiner>
        <TitleText>Classifiche Live</TitleText>
        <p style={{ textAlign: 'center', margin: '0 10px' }}>
          In questa pagina sono contenute le classifiche del mese e quelle annuali aggiornate in tempo reale.
        </p>

        <LeaderboardSwitch value={leaderboardType} onChange={val => setLeaderboardType(val)} />
        
        <LeaderboardConteiner
          title={`Associazioni Registrate - ${interval}`}
          btnOnClick={() => history.push(`/leaderboard/association/${leaderboardType}/false`)}
          loading={loading}
        >
          <LeaderboardRows leaderboard={leaderboards.locations.slice(0, 3)} />
        </LeaderboardConteiner>

        <div style={{ marginBottom: 14 }} />

        <LeaderboardConteiner
          title={`Manifestazioni Effettuate - ${interval}`}
          btnOnClick={()=> history.push(`/leaderboard/manifestation/${leaderboardType}/false`)}
          loading={loading}
        >
          <LeaderboardRows leaderboard={leaderboards.events.slice(0, 3)} />
        </LeaderboardConteiner>

        <ROHButton onClick={() => history.push('/leaderboard/rollofhonor')}>Albo d'oro Classifiche</ROHButton>
      </PageConteiner>
    </>
  );
}

export default LeaderboardListScreen;
