import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const AppSelect = ({ label, labelStyle, wrapperStyle, error, ...props }) => (
  <div style={wrapperStyle}>
    {label && <Label style={labelStyle}>{label}</Label>}
    <Select
      placeholder='seleziona'
      isSearchable={false}
      styles={{
        dropdownIndicator: (base, _) => ({ ...base, color: '#808080' }),
        option: (base, _) => ({ 
          ...base, 
          color: '#000000', 
          backgroundColor: '#FFFFFF' 
        }),
        control: (base, _) => ({
          ...base,
          backgroundColor: error ? '#E5CACA' : undefined,
          borderColor: error ? '#B40000' : '#808080'
        })
      }}
      {...props}
    />
    {error && <ErrorMessage>{error.type === 'required' ? 'Campo obbligatorio' : error.message}</ErrorMessage>}
  </div>
);

const Label = styled.p`
  font-size: 14px;
  margin-bottom: 13px;
  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

const ErrorMessage = styled.p`
  color: #B40000;
  margin: 6px 0 0 12px;
`;

export default AppSelect;
