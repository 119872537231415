import styled from 'styled-components';

export const ContentWrapper = styled.button`
  width: 90vw;
  max-width: 580px;
  display: block;
  padding: 32px 44px;
  margin: 30px auto;
  background-color: #B40000;
  border: none;
  color: #FFFFFF;
  border-radius: 19px;
  text-align: center;
  @media (max-width: 350px) {
    padding: 16px 22px;
    margin: 15px auto;
  }
`;

export const Title = styled.h1`
  margin-top: 100px;
  margin-bottom: 33px;
  font-size: 27px;
  @media (max-width: 350px) {
    font-size: 24px;
  }
`;

export const XPMessage = styled.h2`
  font-weight: 800;
  font-size: 28px;
  @media (max-width: 350px) {
    font-size: 25px;
  }
`;

export const Message = styled.p`
  font-size: 16px;
  @media (max-width: 350px) {
    font-size: 15px;
  }
`;
