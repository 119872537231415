import React from "react";
import { ReactComponent as BlankImg } from "../../assets/no-img.svg";
import { ReactComponent as AddImg } from "../../assets/add-img.svg";
import { ReactComponent as Call } from "../../assets/call.svg";
import styled from "styled-components";

export const NoImg = () => {
  return <BlankImg width={35} height={35} />;
};

export const AddPropic = () => {
  return <AddImg width={28} height={28} />;
};

export const RedCall = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 6,
      }}
    >
      <Call width={20} height={20} />
    </div>
  );
};

export const Popup = styled.div`
  position: absolute;
  margin-top: 60%;
  padding: 40px 10px;
  width: 85%;
  z-index: 1000;
  height: auto;
  background-color: white;
  border-radius: 18px;
  margin-bottom: 18px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 10px 10px;
  @media (min-width: 768px) {
    margin-top: 20%;
    width: 30%;
  }
`;

export const ProfilePic = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 130px;
  background-color: #dfe3e7;
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @media (min-width: 768px) {
    height: 260px;
  }
`;

export const AddImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 12px;
  margin-right: 16px;
`;

export const AddImageText = styled.p`
  font-size: 10;
  color: #808080;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  background-color: #fff;
  width: auto;
  height: auto;
  padding-top: 22px;
`;

export const Wrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  padding-top: 20.8px;
  padding-left: 25px;
  border-radius: 18px;
  margin-bottom: 20px;
`;

export const PopupTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PopupButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: -70px;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 500;
`;

export const GradientWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 24.6px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  padding-top: 18px;
  background-color: #f2f3f8;
  align-items: center;
`;

export const AssociationName = styled.p`
  color: black;
  font-size: 17px;
  width: 239px;
  text-align: center;
  margin-bottom: 5px;
`;

export const AssociationPlace = styled.p`
  color: #808080;
  font-size: 12px;
  text-align: center;
  margin-top: 3px;
`;

export const InformationRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 85%;
  margin-left: 24px;
  margin-top: 13px;
  margin-bottom: 22px;
  @media (min-width: 768px) {
    width: 90%;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: ${(props) => (props.short ? "85%" : "85%")};
`;

export const RedRectangle = styled.div`
  background-color: #b40000;
  width: 33px;
  height: 6px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 80px;
  overflow: auto;
`;

export const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const BlackText = styled.p`
  font-size: 14px;
  margin-bottom: 4px;
  color: black;
`;

export const GrayText = styled.p`
  font-size: 12px;
  color: #808080;
`;

export const ShortText = styled.p`
  font-size: 14px;
  color: #808080;
  margin-bottom: 3px;
`;

export const DescriptionText = styled.p`
  font-size: 12px;
  color: #000000;
  margin-bottom: 14px;
`;

export const LoadFile = styled.input.attrs((props) => ({
  type: "file",
}))`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
