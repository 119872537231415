import React from 'react';
import styled from 'styled-components';
import AppTextInput from '../../components/text-input';

export const Conteiner = styled.div`
  padding: 19px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: 350px;
  margin: 0 auto;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: ${({ margin }) => margin ? margin : 0};
`;

export const Text = styled.p`
  font-size: ${({ fontSize }) => fontSize ? fontSize : 12}px;
  margin: ${({ margin }) => margin ? margin : 0};
  color: ${({ color }) => color ? color : '#000000'};
`;

export const TextInput = styled(AppTextInput).attrs({
  labelStyle: { color: '#000000' },
  wrapperStyle: { marginBottom: 21, width: '100%' }
})``;

const CheckboxBtn = styled.button.attrs({
  type: 'button'
})`
  background: linear-gradient(90deg, #DA0000 0%, #9F0000 100%);
  border-color: #707070;
  border-style: solid;
  border-width: 0;
  padding: 7px;
`;

export const Checkbox = ({ value, onChange=()=>{}, renderLabel=()=>null }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <CheckboxBtn
      style={value ? {} : { borderWidth: 1, padding: 6, background: 'transparent' }}
      onClick={() => onChange(value ? 0 : 1)}
    />
    <div style={{ marginLeft: 5 }} />
    {renderLabel()}
  </div>
);

export const SubmitButton = styled.button`
  padding: 11px 2px;
  width: 100%;
  background: linear-gradient(90deg, #DA0000 0%, #9F0000 100%);
  border-radius: 7px;
  border: none;
  color: #FFFFFF;
  font-size: 18px;
  margin-top: 32px;
`;

export const LogoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 70px 0 97px 0;
  overflow: hidden;
  background-color: #B40000;
  clip-path: polygon(0 0, 0 100%, 100% 75%, 100% 0);
`;

export const ErrorText = styled.p`
  margin: 20px 0;
  font-size: 14px;
  text-align: center;
  color: #B40000;
`;
