import styled from "styled-components";

export const Tracker = styled.div`
  background-color: #DFE3E7;
  position: relative;
  height: ${props => props.isSmall ? '4px' : '14px'};
  width: 95%;
  @media (min-width: 768px) {
     width:${props => props.windowMode && "140%"};
  }
  border-radius: 2px;
`

export const ProgressInTracker = styled.div`
  width: ${props => props.width};
  height: 100%;
  background: ${props => props.isSmall ? '#B40000' : '#39B54A'};
  border-radius: 2px;
`


