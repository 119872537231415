import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import AppContext from '../../services/context.service';

import { ReactComponent as LogoSvg } from '../../assets/logo.svg';
import {
  Conteiner,
  Text,
  TextInput,
  SubmitButton,
  LogoWrapper,
  ErrorText
} from './login.style';

function RecoverPassword() {
  const history = useHistory();
  const { requestPassword } = useContext(AppContext);
  const [reset, setReset] = useState(false);
  const { register, handleSubmit, errors, setError } = useForm({
    defaultValues: {
      email: '',
    }
  });

  async function onSubmit({ email }) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    try {
      await requestPassword(email);
      setReset(true);
    } catch (e) {
      const { response } = e;
      if (!response) {
        return setError('mainError', 'unknown', 'Impossibile richiedere il reset password, riprova più tardi');
      }

      switch (response.status) {
        case 404: setError('email', '404', 'Utente inesistente'); break;
        case 422: setError('password', '422', 'Password non valida'); break;
        default: break;
      }
    }
  }

  if (!reset) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <LogoWrapper><LogoSvg /></LogoWrapper>
        <Conteiner>
          <Text fontSize='27' color='#1A1A1A'>Recupero password</Text>
          <Text margin='10px 0 5px 0' style={{ textAlign: 'center' }}>Inserisci la tua email per ricevere una mail per il recupero password</Text>

          <ErrorText>{errors.mainError && errors.mainError.message}</ErrorText>

          <TextInput
            name="email"
            autoComplete="on"
            error={errors.email}
            label="Indirizzo email"
            ref={register({ required: true })}
          />

          <SubmitButton>Recupera</SubmitButton>
        </Conteiner>
      </form>
    );
  } else {
    return (
      <>
        <LogoWrapper><LogoSvg /></LogoWrapper>
        <Conteiner>
          <Text fontSize='27' color='#1A1A1A'>Email inviata</Text>
          <Text margin='15px 0 5px 0' style={{ textAlign: 'center' }}>Controlla la posta elettronica per cambiare la password.</Text>

          <SubmitButton onClick={() => history.push("/login")}>Torna al login</SubmitButton>
        </Conteiner>
      </>
    );
  }
}

export default RecoverPassword;
