import React from 'react';
import styled, { css } from 'styled-components';
import AppButton from '../app-button';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import {ReactComponent as EuroSvg} from '../../assets/euro.svg';
import {ReactComponent as CalendarGridSvg} from '../../assets/modal-calendar.svg';
import {ReactComponent as GreyXSvg} from '../../assets/modal-grey-x.svg';

export const Content = ({ children, closeForm }) => (
  <ContentWrapper>
    {children}
    <XButton onClick={closeForm}>
      <GreyXSvg />
    </XButton>
  </ContentWrapper>
);

const ContentWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 22px 0;
  background-color: white;
  min-width: 250px;
  width: 95vw;
  max-width: 370px;
  border-radius: 18px;
  box-sizing: border-box;
`;

const XButton = styled.button`
  position: absolute;
  right: 16px;
  top: 20px;
  border: none;
  background-color: transparent;
`;

export const Conteiner = styled.div`
  padding: 0 24px;
  @media (max-width: 375px) {
    padding: 0 18px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Line = styled.hr`
  background-color: #DFE3E7;
  border: none;
  height: 1px;
  margin: 22px 0;
`;

export const Title = styled.h1`
  font-size: 18px;
  color: #505050;
  text-align: center;
`;

export const Label = styled.p`
  font-size: 14px;
  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

export const ConfirmButton = styled(AppButton)`
  display: block;
  margin: 40px auto 18px auto;
`;

export const SubTitle = ({ style, ...props }) => (
  <div style={{ padding: '9px 0', position: 'relative' }}>
    <Title style={style} {...props} />
    <LeftRedRect />
  </div>
);

export const SubTitleLines = ({ style, lines }) => (
  <div style={{ padding: '9px 0', position: 'relative' }}>
    {lines.map(line => <Title key={line} style={style}>{line}</Title>)}
    <LeftRedRect />
  </div>
);

const LeftRedRect = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #B40000;
`;

export const PairFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MoneySummaryWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 23px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const ValueIconField = ({ 
  label, 
  value, 
  renderIcon, 
  wrapperStyle, 
  isInput,
  isNumber, 
  onChange, 
  width,
  highlightError
}) => (
  <div style={wrapperStyle}>
    <Label>{label}</Label>
    <ValueIconBox style={highlightError ? { backgroundColor: '#E5CACA', borderColor: '#B40000' } : {}}>
      <ValueWrapper style={highlightError ? { borderColor: '#B40000' } : {}}>
        <ValueIconInput
          style={highlightError ? { backgroundColor: '#E5CACA' } : {}}
          onChange={(e) => {
            let newValue = e.target.value;
            if (isNumber) {
              if (isNaN(newValue) || +newValue < 0) return;
            }
            onChange(newValue);
          }}
          {...{ value, width }} 
          disabled={!isInput} 
          type={isNumber ? 'number' : 'text'}
        />
      </ValueWrapper>
      {renderIcon()}
    </ValueIconBox>
  </div>
);

const ValueIconInput = styled.input`
  font-size: 14px;
  color: #000000;
  border: none;
  ${p => p.width && css`
    width: ${p => p.width}px;
  `}
  text-align: center;
  
  &:disabled {
    cursor: text;
    background-color: transparent;
  }

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } 
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } 
`;

const ValueIconBox = styled.div`
  margin-top: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 37px;
  border-radius: 4px;
  border: solid #808080 1px;
`;

const ValueWrapper = styled.div`
  padding: 0 10px;
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid #808080 1px;
`;

export const MoneyField = styled(ValueIconField).attrs(p => ({
  width: 60,
  isNumber: true,
  value: p.value,
  renderIcon: () => <EuroSvg style={{ marginLeft: 8, marginRight: 8 }} />,
  highlightError: p.highlightError
}))``;

export const DateField = ({ value, label, onChange=()=>{} }) => (
  <DatePicker 
    selected={value?.toDate()} 
    dateFormat='dd/MM/yyyy' 
    onChange={(date) => onChange(moment(date))}
    customInput={
      <DatePickerInput 
        label={label}
        renderIcon={() => <CalendarGridSvg style={{ marginLeft: 8, marginRight: 8 }} />}
      />
    }
  />
);

class DatePickerInput extends React.Component {
  render() {
    const { label, value, onClick, wrapperStyle, renderIcon } = this.props;
    return (
      <div style={wrapperStyle}>
        <Label>{label}</Label>
        <ValueIconBox onClick={onClick} style={{ width: 130, cursor: 'pointer' }}>
          <ValueWrapper>
            <Label style={{ color: '#000000' }}>{value}</Label>
          </ValueWrapper>
          {renderIcon()}
        </ValueIconBox>
      </div>
    );
  }
}

export const TextField = ({ label, text, style }) => (
  <div>
    <Label>{label}</Label>
    <Label style={{ color: '#000000', marginTop: 27, marginLeft: 12, ...style }}>{text}</Label>
  </div>
);

export const CheckBoxField = ({ value: { value: flag }, onChange=()=>{}, label, wrapperStyle }) => (
  <CheckBoxFieldWrapper style={wrapperStyle}>
    <CheckBox onClick={() => onChange({ value: !flag })} style={{ backgroundColor: flag ? '#B40000' : '#FFFFFF' }} />
    <div style={{ margin: '0 7px', width: '80%' }}>
      <Label>{label}</Label>
    </div>
  </CheckBoxFieldWrapper>
);

const CheckBoxFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 300px;
  @media (max-width: 400px) {
    width: 250px;
  }
`;

const CheckBox = styled.button.attrs({ type: 'button' })`
  padding: 0;
  height: 15px;
  width: 15px;
  border: solid #808080 1px;
  border-radius: 3px;
`;

export const PlusButton = ({ onClick, disabled }) => (
  <PlusButtonWrapper onClick={onClick} disabled={disabled}>
    <PlusButtonLine vertical />
    <PlusButtonLine horizontal />
  </PlusButtonWrapper>
);

const PlusButtonWrapper = styled.button.attrs({ type: 'button' })`
  position: relative;
  height: 37px;
  width: 37px;
  background-color: ${props => props.disabled ? '#E5CACA' : '#B40000'};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  border: none;
  border-radius: 4px;
`;

const PlusButtonLine = styled.div`
  position: absolute;
  background-color: #FFFFFF;
  border-radius: 5px;
  ${p => p.vertical && css`
    top: 20%;
    bottom: 20%; 
    left: 40%; 
    right: 40%;
  `}
  ${p => p.horizontal && css`
    left: 20%;
    right: 20%; 
    top: 40%; 
    bottom: 40%;
  `}
`;

export const StateButton = styled.button.attrs(p => ({
  type: 'button',
  disabled: p.filled
}))`
  font-size: 16px;
  padding: 9px 10px;
  ${p => p.filled ? css`
    background-color: ${p => p.color};
    color: #FFFFFF;
  ` : css`
    background-color: #FFFFFF;
    color: ${p => p.color};
  `}
  border: solid ${p => p.color} 1px;
  border-radius: 4px;
  @media (max-width: 350px) {
    padding: 9px 6px;
    font-size: 14px;
  }
`;

export const TextAreaField = ({ onChange, label, ...props }) => (
  <div>
    <Label>{label}</Label>
    <Textarea onChange={({ target: { value } }) => onChange(value)} {...props} />
  </div>
);

const Textarea = styled.textarea.attrs({
  rows: 4
})`
  margin-top: 12px;
  padding: 12px;
  align-self: stretch;
  resize: none;
  border: solid #808080 1px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;

  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #000000;

  &::placeholder {
    font-weight: 600;
    color: #808080;
  }
`;

export const ErrorMessage = styled.p`
  color: #B40000;
  margin: 6px 0 0 12px;
`;
