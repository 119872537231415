import React, { useEffect } from 'react';
import Modal from '../modal';

import ManifestationCreateForm from './create-form';
import { useHistory } from 'react-router-dom';

// usato per forzare il caricamento del calendario senza dover ricarcare la pagine
let refreshIndicator = 0;

function ManifestationModal({ data={}, visible, hideModal, onSubmit }) {
  const history = useHistory();
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [visible]);

  return (
    <Modal {...{ visible, hideModal }}>
      <ManifestationCreateForm
        data={data}
        id={data}
        label={data} 
        onSubmit={(data) => {
          hideModal();
          if (onSubmit) {
            onSubmit(data);
          } else {
            history.push('/calendar/self', { refreshIndicator: refreshIndicator++ });
          }
        }}
        closeForm={hideModal}
      />
    </Modal>
  );
}

export default ManifestationModal;
