import React from "react";
import { useHistory } from "react-router-dom";
import {
  Wrapper,
  RegisteredText,
  ExtraboldText,
  Body,
  Done,
  EndText,
  NextText
} from "./add_complete.style";

function CompleteScreen() {
  const history = useHistory();

  const next = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    history.push("/");
  };

  return (
    <Body>
      <Wrapper>
        <RegisteredText>ASSOCIAZIONE REGISTRATA</RegisteredText>
        <Done />
        <ExtraboldText style={{ marginBottom: 6 }}>
          CONGRATULAZIONI
        </ExtraboldText>
        <ExtraboldText style={{ marginBottom: 49 }}>+ 10 XP</ExtraboldText>
        <EndText>
          Potrai ritrovare l’associazione creata nel tuo ELENCO ASSOCIAZIONI
        </EndText>
        <div onClick={() => next()} style={{cursor: 'pointer'}}>
          <NextText>Tocca per continuare</NextText>
        </div>
      </Wrapper>
    </Body>
  );
}

export default CompleteScreen;
