/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Container from "../../components/container.component";
import GoBack from "../../components/goback.component";
import Paging from "../../components/paging.component";
import Api from "../../services/api.service";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import {
  Wrapper,
  Body,
  SearchWrapper,
  InputText,
  Lens,
  AssociationWrapper,
  AssociationName,
  AssociationPlace,
  RedDots,
  NextDivisor,
  BackToFirst,
  ButtonsWrapper,
  Back,
  Next,
  NextToLast,
  ErrorMessage,
  AddButton,
} from "./association_list.style";

function AssociationList() {
  const [error, setError] = useState(false);
  const [results, setResults] = useState();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [profile, setProfile] = useState();
  const history = useHistory();
  const searchLoc = useRef();

  const getProfile = async () => {
    try {
      const res = await Api.getProfile();
      setProfile(res.data);
    } catch (e) {
      // console.log(e.message);
    }
  };

  const searchLocation = async (key) => {
    if (error) {
      setError(false);
    }
    try {
      if (key === "") {
        
        const res = await Api.getLocations(page, profile.user.id);
        
        if (res.data.data.length === 0) {
          
          const res2 = await Api.getLocations(
            res.data.last_page,
            profile.user.id
          );
          setResults(res2.data);
          setPage(res.data.last_page);
        } else {
          setResults(res.data);
        }
      } else {
        const res = await Api.searchLocation(key, 1, profile.user.id);
        if (res.data.data.length === 0) {
          const res2 = await Api.searchLocation(
            res.data.last_page,
            profile.user.id
          );
          setResults(res2.data);
          setPage(res.data.last_page);
        } else {
          setResults(res.data);
          setPage(1);
        }
      }
    } catch (e) {
      const { response } = e;
      switch (response.status) {
        case 404:
          setError(true);
          break;
        default:
          break;
      }
    }
  };

  const searchLocationArrows = async (key) => {
    if (error) {
      setError(false);
    }
    try {
      if (key === "") {
        const res = await Api.getLocations(page, profile.user.id);
        if (res.data.data.length === 0) {
          const res2 = await Api.getLocations(
            res.data.last_page,
            profile.user.id
          );
          setResults(res2.data);
          setPage(res.data.last_page);
        } else {
          setResults(res.data);
        }
      } else {
        const res = await Api.searchLocation(key, page, profile.user.id);
        if (res.data.data.length === 0) {
          const res2 = await Api.searchLocation(
            res.data.last_page,
            profile.user.id
          );
          setResults(res2.data);
          setPage(res.data.last_page);
        } else {
          setResults(res.data);
        }
      }
    } catch (e) {
      const { response } = e;
      switch (response.status) {
        case 404:
          setError(true);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (profile !== undefined) {
      searchLocation(search);
    }
  }, [search, profile]);

  useEffect(() => {
    if (profile !== undefined) {
      searchLocationArrows(search);
    }
  }, [page]);

  const lastPage = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage(results.last_page);
  };

  const firstPage = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage(1);
  };

  const openAssociation = (id) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    history.push({
      pathname: "/association",
      state: id,
    });
  };

  const previousPage = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage(page - 1);
  };

  const nextPage = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage(page + 1);
  };
  if (results) {
    return (
      <>
        <GoBack />
        <Body>
          <Container
            title={"Elenco Associazioni"}
            content={
              <>
                <Wrapper>
                  {error && (
                    <ErrorMessage>Nessuna corrispondenza trovata</ErrorMessage>
                  )}
                  <SearchWrapper>
                    <InputText
                      onKeyDown={(e) =>
                        e.key === "Enter" && setSearch(searchLoc.current.value)
                      }
                      placeholder={"Ricerca per parola chiave"}
                      ref={searchLoc}
                    />
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setSearch(searchLoc.current.value);
                      }}
                    >
                      <Lens />
                    </div>
                  </SearchWrapper>
                  {results.data.map((result) => (
                    <div
                      key={result.id}
                      onClick={() => openAssociation(result.id)}
                    >
                      <AssociationWrapper key={result.id + "wrapper"}>
                        <AssociationName key={result.id + "name"}>
                          {result.name}
                        </AssociationName>
                        <AssociationPlace key={result.id + "place"}>
                          {result.place?.type === 'local' ? result.place.city : result.place?.comune}
                        </AssociationPlace>
                        <RedDots key={result.id + "red"} />
                        <NextDivisor key={result.id + "div"} />
                      </AssociationWrapper>
                    </div>
                  ))}
                </Wrapper>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ButtonsWrapper>
                    {page > 1 && (
                      <div onClick={() => firstPage()}>
                        <BackToFirst />
                      </div>
                    )}
                    {page > 1 && (
                      <div onClick={() => previousPage()}>
                        <Back />
                      </div>
                    )}
                    <Paging page={page} results={results.last_page} />
                    {page < results.last_page && (
                      <div onClick={() => nextPage()}>
                        <Next />
                      </div>
                    )}
                    {page < results.last_page && (
                      <div onClick={() => lastPage()}>
                        <NextToLast />
                      </div>
                    )}
                  </ButtonsWrapper>
                </div>
              </>
            }
          ></Container>
          <AddButton onClick={() => history.push("/addassociation")}>+ Aggiungi Associazione</AddButton>
        </Body>
      </>
    );
  } else
    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginTop: "20%",
        }}
      >
        <Loader type="Oval" color="#B40000" height={100} width={100} />
      </div>
    );
}

export default AssociationList;
