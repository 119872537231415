import { useState, useCallback } from 'react';

import Api from '../../services/api.service';

function useRank() {
  const [loading, setLoading] = useState(false);
  const [rank, setRank] = useState({});

  const fetchRank = useCallback(async (dateMin, dateMax) => {
    setLoading(true);
    try {
      const [{ data: locData }, { data: evtData }] = await Promise.all([
        Api.leaderboardLocations(dateMin, dateMax),
        Api.leaderboardEvents(dateMin, dateMax)
      ]);

      setRank({
        association: locData.my_result,
        manifestation: evtData.my_result
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return { rank, loading, fetchRank };
}

export default useRank;
