/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import Modal from "../../components/side_menu_modal";
import Api from "../../services/api.service";
import AppContext from "../../services/context.service";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  HeaderBar,
  IpohLogo,
  SideMenuButton,
  Container,
  Menu,
} from "./header.style";

function Header() {
  const { openManifestationModal } = useContext(AppContext);
  const [sideMenu, setSideMenu] = useState(false);
  const [profile, setProfile] = useState();
  const history = useHistory();

  function onAdd() {
    setSideMenu(!sideMenu);
    openManifestationModal({ create: true, date: moment() });
  }

  const getProfile = async () => {
    try {
      const res = await Api.getProfile();
      setProfile(res.data);
    } catch (e) {
      // console.log(e.message);
    }
  };

  const backHome = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    history.push("/");
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <HeaderBar>
      <Modal
        sideMenu={sideMenu}
        setSideMenu={setSideMenu}
        onAdd={onAdd}
        profile={profile}
      />
      <Container>
        <Menu onClick={() => setSideMenu(!sideMenu)}>
          <SideMenuButton />
        </Menu>
        <IpohLogo />
        <div
          onClick={() => backHome()}
          style={{ cursor: "pointer", width: 35 }}
        >
          {profile && (
            <div
              style={{
                width: 35,
                height: 35,
                borderRadius: 100,
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: "#b40000",
                backgroundImage: `url(${
                  "https://admin.ipoh.app/" + profile.user.user_pic_url
                })`,
                backgroundSize: "cover",
              }}
            />
          )}
        </div>
      </Container>
    </HeaderBar>
  );
}

export default Header;
