import React from 'react';
import styled, { css } from 'styled-components';

import {ReactComponent as LeftArrowSvg} from '../../assets/arrow-left-white.svg';

export const PageWrapper = styled.div`
  width: 100%;
`;

export const PageTitle = styled.h1`
  margin: 20px 0;
  font-size: 27px;
  text-align: center;
  color: #000000;
`;

export const PageConteiner = ({ children }) => (
  <Conteiner>
    <ConteinerWrapper>{children}</ConteinerWrapper>
  </Conteiner>
);

const Conteiner = styled.div`
  padding: 0 25px;
  margin: 0 auto;
  min-width: 250px;
  max-width: 640px;
  box-sizing: border-box;

  @media (max-width: 350px) {
    padding: 0 15px;
  }
`;

const ConteinerWrapper = styled.div`
  padding: 23px 20px;
  background-color: #fff;
  border-radius: 18px;
  margin-bottom: 18px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 10px 10px;
`;

export const AddButton = styled.button`
  display: block;
  margin: 0 auto 30px auto;
  padding: 12px 10px;
  border-radius: 6px;
  color: #FFFFFF;
  background: rgb(218,0,0);
  background: linear-gradient(90deg, rgba(218,0,0,1) 0%, rgba(159,0,0,1) 100%);
  border: none;
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 47px;
  border: none;
  background-color: ${p => p.isGray ? '#F2F3F8' : '#B40000'};
  font-size: 18px;
  color: ${p => p.isGray ? '#B40000' : '#FFFFFF' };
  ${p => p.left && css`
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  `}
  ${p => p.right && css`
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  `}
  @media (max-width: 375px) {
    width: 37px;
    font-size: 16px;
  }
`;

export const Arrow = styled(LeftArrowSvg)`
  transform: scale(1.2);
`;
