import React from "react";
import {Tracker, ProgressInTracker} from "./progressbar.component.style.js";

function ProgressBar(props) {
  const { width, isSmall, windowMode } = props;

  return (
    <Tracker isSmall={isSmall} windowMode={windowMode}>
      <ProgressInTracker width={width} isSmall={isSmall}/>
    </Tracker>
  );
}

export default ProgressBar;
