import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import useRank from './useRank';

import {ReactComponent as BigCup} from '../../assets/big-cup.svg';

import {
  ContentWrapper,
  PositionText,
  Text
} from './completed-leaderboards.style';

function CompletedLeaderboardsScreen() {
  const { rank: { association, manifestation }, loading, fetchRank } = useRank();
  const history = useHistory();
  const date = useMemo(() => moment(), []);
  
  useEffect(() => {
    const dateMin = moment(date).startOf('month');
    const dateMax = moment(date).endOf('month');
    fetchRank(dateMin, dateMax);
  }, [date, fetchRank]);

  return (
    <ContentWrapper disabled={loading} onClick={() => history.push('/leaderboard/rollofhonor')}>
      {loading ? <Text size='14'>Caricamento in corso...</Text> : <>
        <Text size='27' mTop='30' mBot='31'>Classifiche Concluse</Text>
        <BigCup />
        <Text size='16' mTop='50' mBot='28' color='#FCEE21'>TI SEI CLASSIFICATO</Text>

        <PositionText>{association ? `${association.position}° POSTO` : `NON CLASSIFICATO`}</PositionText>
        <Text size='21'>Associazioni Registrate</Text>
        <Text size='21' mBot='29' style={{ textTransform: 'capitalize' }}>{date.format('MMMM YYYY')}</Text>

        <PositionText>{manifestation ? `${manifestation.position}° POSTO` : `NON CLASSIFICATO`}</PositionText>
        <Text size='21'>Manifestazioni effettuate</Text>
        <Text size='21' mBot='50' style={{ textTransform: 'capitalize' }}>{date.format('MMMM YYYY')}</Text>

        <Text>Tocca per Visualizzare le classifiche</Text>
    </>}
    </ContentWrapper>
  );
}

export default CompletedLeaderboardsScreen;
