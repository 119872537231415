import React, { useEffect, useState } from "react";
import Api from "../../services/api.service";
import GoBack from "../../components/goback.component";
import Tooltip from "rc-tooltip";
import Loader from "react-loader-spinner";
import "../../app.css";
import {
  Body,
  RedBand,
  RedBandText,
  RedBandSubText,
  AchievementsRow,
  AchievementColumn,
  AchievementText,
  TooltipDiv,
  TooltipText,
  GrayImage,
} from "./achievements.style";

function Achievements(props) {
  const [lockedBadges, setLockedBadges] = useState();
  const [unlockedBadges, setUnlockedBadges] = useState();

  const getBadges = async () => {
    try {
      const res = await Api.getBadges();
      let chunk_size = 3;
      let tempArray = res.data.locked
        .map(function (e, i) {
          return i % chunk_size === 0
            ? res.data.locked.slice(i, i + chunk_size)
            : null;
        })
        .filter(function (e) {
          return e;
        });
      let tempArray2 = res.data.unlocked
        .map(function (e, i) {
          return i % chunk_size === 0
            ? res.data.unlocked.slice(i, i + chunk_size)
            : null;
        })
        .filter(function (e) {
          return e;
        });
      setLockedBadges(tempArray);
      setUnlockedBadges(tempArray2);
    } catch (e) {
      // console.log(e.message);
    }
  };

  useEffect(() => {
    getBadges();
  }, []);

  if (lockedBadges !== undefined && unlockedBadges !== undefined) {
    return (
      <>
        <GoBack />
        <Body>
          <RedBand>
            <RedBandText>Riconoscimenti</RedBandText>
            <RedBandSubText>
              Conquista i badges e dimostra che sei il migliore
            </RedBandSubText>
          </RedBand>
          {unlockedBadges.map((array) => {
            return (
              <AchievementsRow>
                {array.map((badge) => {
                  return (
                    <AchievementColumn>
                      <Tooltip
                        trigger={["click"]}
                        placement="top"
                        align={{
                          offset: [0, 40],
                        }}
                        transitionName={"rc-tooltip-zoom"}
                        overlay={
                          <TooltipDiv>
                            <TooltipText>{badge.description}</TooltipText>
                          </TooltipDiv>
                        }
                      >
                        <img
                          src={`https://admin.ipoh.app/${badge.icon.url}`}
                          style={{cursor: 'pointer', width: 82, heigth: 82}}
                          alt={"badge"}
                        ></img>
                      </Tooltip>
                      <AchievementText>{badge.name}</AchievementText>
                    </AchievementColumn>
                  );
                })}
              </AchievementsRow>
            );
          })}
          {lockedBadges.map((array) => {
            return (
              <AchievementsRow>
                {array.map((badge) => {
                  return (
                    <AchievementColumn>
                      <Tooltip
                        trigger={["click"]}
                        placement="top"
                        align={{
                          offset: [0, 40],
                        }}
                        transitionName={"rc-tooltip-zoom"}
                        overlay={
                          <TooltipDiv>
                            <TooltipText>{badge.description}</TooltipText>
                          </TooltipDiv>
                        }
                      >
                        <GrayImage
                          src={`https://admin.ipoh.app/${badge.icon.url}`}
                          alt={"badge"}
                        ></GrayImage>
                      </Tooltip>
                      <AchievementText>{badge.name}</AchievementText>
                    </AchievementColumn>
                  );
                })}
              </AchievementsRow>
            );
          })}
        </Body>
      </>
    );
  } else return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        marginTop: "20%",
      }}
    >
      <Loader type="Oval" color="#B40000" height={100} width={100} />
    </div>
  );
}

export default Achievements;
