import React, { useState, useEffect } from "react";
import Api from "../../services/api.service";
import GoBack from "../../components/goback.component";
import Reward from "../../components/reward.component";
import Loader from "react-loader-spinner";
import {
  Body,
  RedBand,
  RedBandText,
  RedBandSubText,
} from "./achievements2.style";

function Achievements2(props) {
  const [badges, setBadges] = useState();
  const getBadges = async () => {
    try {
      const res = await Api.getBadges();
      setBadges(res.data);
    } catch (e) {
      // console.log(e.message);
    }
  };

  useEffect(() => {
    getBadges();
  }, []);
  // console.log(badges)
  if (badges !== undefined) {
    return (
      <>
        <GoBack />
        <RedBand>
          <RedBandText>Riconoscimenti</RedBandText>
          <RedBandSubText>
            Conquista i badges e dimostra che sei il migliore
          </RedBandSubText>
        </RedBand>
        <Body>
          {badges.unlocked.map((badge) => {
            if (badge.progress) {
              return (
                <Reward
                  icon={`https://admin.ipoh.app/${badge.icon.url}`}
                  title={badge.name}
                  description={badge.description}
                  isExp
                  exp={badge.progress}
                  windowMode
                />
              );
            } else return (
              <Reward
                icon={`https://admin.ipoh.app/${badge.icon.url}`}
                title={badge.name}
                description={badge.description}
                windowMode
              />
            );
          })}
          {badges.locked.map((badge) => {
            if (badge.progress || badge.progress === 0) {
              return (
                <Reward
                  icon={`https://admin.ipoh.app/${badge.icon.url}`}
                  title={badge.name}
                  description={badge.description}
                  isExp
                  exp={badge.progress}
                  windowMode
                  locked
                />
              );
            } else {
              return (
                <Reward
                  icon={`https://admin.ipoh.app/${badge.icon.url}`}
                  title={badge.name}
                  description={badge.description}
                  windowMode
                  locked
                />
              );
            }
          })}
        </Body>
      </>
    );
  } else
    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginTop: "20%",
        }}
      >
        <Loader type="Oval" color="#B40000" height={100} width={100} />
      </div>
    );
}

export default Achievements2;
