import React from 'react';
import Switch from 'react-switch';
import AppButton from '../../components/app-button';
import styled from 'styled-components';

export const PageConteiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
`;

export const TitleText = styled.h1`
  font-size: 27px;
  color: #000000;
  margin-bottom: 10px;
`;

export const ROHButton = styled.button`
  display: block;
  margin: 0 auto 30px auto;
  padding: 12px 10px;
  border-radius: 6px;
  color: #FFFFFF;
  background: rgb(218,0,0);
  background: linear-gradient(90deg, rgba(218,0,0,1) 0%, rgba(159,0,0,1) 100%);
  border: none;
`;

export const LeaderboardSwitch = ({ value, onChange=()=>{} }) => (
  <div style={{  display: 'flex', alignItems: 'center', marginTop: 22, marginBottom: 27 }}>
    <p style={{ marginRight: 5 }}>MENSILE</p>
    <Switch
      checked={value === 'year'}
      offColor={"#B40000"}
      onColor={"#B40000"}
      height={25}
      width={43}
      handleDiameter={25}
      uncheckedIcon={false}
      checkedIcon={false}
      onChange={flag => onChange(flag ? 'year' : 'month')}
      activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    />
    <p style={{ marginLeft: 5 }}>ANNUALE</p>
  </div>
);

export const LeaderboardConteiner = ({ title, children, btnOnClick, loading }) => (
  <Wrapper>
    <HeaderContainer>
      <RedRectangle />
      <TitleContainer>{title}</TitleContainer>
    </HeaderContainer>
    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}>
      {loading ? <p style={{ textAlign: 'center', fontSize: 20, marginBottom: 30 }}>Caricamento in corso...</p> : (
        <>
          {children}
          <AppButton style={{ margin: '20px auto' }} onClick={btnOnClick}>Visualizza Classifica Completa</AppButton>
        </>
      )}
    </div>
  </Wrapper>
);

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 18px;
  margin-bottom: 18px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 10px 10px;
  padding-top: 21px;
  width: 90vw;
  max-width: 640px;
`;

const TitleContainer = styled.p`
  font-size: 16px;
  color: black;
  margin-left: 13px;
  flex-grow: 1;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
`;

const RedRectangle = styled.div`
  width: 5px;
  height: 33px;
  background-color: #b40000;
`;
