export const listToMatrix = (list, rowCount) => {
  const matrix = [];
  let k = -1;

  for (let i=0; i < list.length; i++) {
      if (i % rowCount === 0) {
          k++;
          matrix[k] = [];
      }
      matrix[k].push(list[i]);
  }

  return matrix;
}

export const normalizeTimeString = (time) => {
  if (!time) return '';
  let [h, m] = time.split(':');

  h = ('0' + h).slice(-2);
  m = ('0' + m).slice(-2);

  return `${h}:${m}`;
}

export const validateTime = (time) => {
  const parts = time.split(':');
  if (parts.length !== 2) return false;
  let [h, m] = parts;
  if (h.length !== 2 || m.length !== 2) return false;
  h = +h;
  m = +m;
  return h >= 0 && h <= 23 && m >= 0 && m <= 59;
};
