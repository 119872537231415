import React from "react";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import {
  IpohLogo,
  SideMenuContainer,
  LogoWrapper,
  SideMenuText,
  SideMenuCategory,
  Profile,
  Edit,
  Calendar,
  Credits,
  Leaderboard,
  MyTeam,
  Close,
  CloseMenu,
  SecondMenu,
  HeadWrapper,
  RedRectangle,
  AddText,
  ContentWrapper,
  TransparentLogo,
  Logout
} from "./side_menu_modal.style";

function SideModal(props) {
  const { sideMenu, setSideMenu, onAdd, profile } = props;

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0,0,0,0.6)",
    },
    content: {
      top: 0,
      left: sideMenu ? 0 : 0,
      right: 0,
      bottom: 0,
      padding: 0,
      width: 252,
      height: "100%",
      transition: "0.5s",
    },
  };
  const history = useHistory();

  const changePage = (page) => {
    history.push(page);
    setSideMenu(!sideMenu);
  };

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  if (profile) {
    return (
      <Modal
        isOpen={sideMenu}
        style={customStyles}
        closeTimeoutMS={500}
        ariaHideApp={false}
        onRequestClose={() => setSideMenu(!sideMenu)}
      >
        <SideMenuContainer>
          <TransparentLogo />
          <LogoWrapper>
            <IpohLogo />
            <CloseMenu onClick={() => setSideMenu(!sideMenu)}>
              <Close />
            </CloseMenu>
          </LogoWrapper>
          <SideMenuCategory onClick={() => changePage("/")}>
            <Profile />
            <SideMenuText>Profilo</SideMenuText>
          </SideMenuCategory>
          <SideMenuCategory onClick={() => changePage("/associations_list")}>
            <Edit />
            <SideMenuText>Associazioni</SideMenuText>
          </SideMenuCategory>
          <SideMenuCategory onClick={() => changePage("/calendar/self")}>
            <Calendar />
            <SideMenuText>Calendario</SideMenuText>
          </SideMenuCategory>
          <SideMenuCategory onClick={() => changePage("/achievements2")}>
            <Credits />
            <SideMenuText>Riconoscimenti</SideMenuText>
          </SideMenuCategory>
          <SideMenuCategory onClick={() => changePage("/leaderboard/list")}>
            <Leaderboard />
            <SideMenuText>Classifiche</SideMenuText>
          </SideMenuCategory>
          {profile.user.role !== "organizer" && (
            <SideMenuCategory onClick={() => changePage("/myteam")}>
              <MyTeam />
              <SideMenuText>Il Mio Team</SideMenuText>
            </SideMenuCategory>
          )}
          <SideMenuCategory onClick={() => logout()}>
            <Logout />
            <SideMenuText>Logout</SideMenuText>
          </SideMenuCategory>
        </SideMenuContainer>
        <SecondMenu>
          <HeadWrapper>
            <RedRectangle />
            <SideMenuText style={{ marginLeft: 46, cursor: "default" }}>
              Aggiungi
            </SideMenuText>
          </HeadWrapper>
          <ContentWrapper>
            <AddText onClick={() => onAdd()}>+ Manifestazione</AddText>
            <AddText onClick={() => changePage("/addassociation")}>
              + Associazione
            </AddText>
          </ContentWrapper>
        </SecondMenu>
      </Modal>
    );
  } else return <></>;
}
export default SideModal;
