/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext } from "react";
import GoBack from "../../components/goback.component";
import Container from "../../components/container.component";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Api from "../../services/api.service";
import AppContext from "../../services/context.service";
import Province from "../../assets/province.txt";
import AssetComuni from "../../assets/comuni.json";
import "../../app.css";
import {
  Wrapper,
  Wrapper2,
  Body,
  InputText,
  Row,
  Column,
  ColumnFix,
  InputSelect,
  InputNumber,
  NextWrapper,
  RedText,
  NextDivisor,
  HeaderContainer,
  RedRectangle,
  TitleContainer,
  SecondRow,
  InputTextArea,
  ShortText,
  DescriptionText,
  Button,
  ErrorMessage,
  Error,
  Autocomplete,
  Sample,
  Cancel,
  GrayText,
  AutocompleteWrapper,
} from "./add_association.style";

import AsyncSelect from 'react-select/async';

const formeGiuridiche = {
  'IND': 'Impresa individuale',
  'SPA': 'S.p.A.',
  'SRL': 'S.r.l.',
  'SNC': 'S.n.c.',
  'SAS': 'S.a.s.',
  'SSE': 'Società Semplice',
  'COO': 'Cooperativa',
  'ALT': 'Altro',
  'PUB': 'Ente pubblico',
}

function AddAssociation(props) {
  const { openLastManifestationModal } = useContext(AppContext);
  const { register, handleSubmit, errors, setError, watch } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [location, setLocation] = useState();
  const [type, setType] = useState();
  const [textFormaGiuridica, setTextFormaGiuridica] = useState("IND");
  const [step, setStep] = useState(1);

  const [info, setInfo] = useState({
    referents: [{}],
    additional_infos: {},
    photo: null,
  });
  const [refs, setRefs] = useState(Array(1).fill(0));
  const history = useHistory();
  let referentFirst, referentLast, email, tel1, tel2;
  const codiceFiscale = watch('codice_fiscale');

  const createLocation = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await Api.createLocation(info);
      if (props.location.state === "create-manifestation-step") {
        openLastManifestationModal({ id: data.id, label: data.name });
        return history.goBack();
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      history.push("/add_complete");
    } catch (e) {
      const { response } = e;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (typeof response.data === 'string') {
        setError(
          "retry",
          response.status,
          "*"+response.data,
        );
      } else {
        setError("retry", response.status, Object.entries(response.data).map(([key, value]) => <div>{key}: {value}</div>));
      }
      
      setStep(1);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    return () => {
      if (props.location.state === "create-manifestation-step") {
        openLastManifestationModal();
      }
    };
  }, []);

  const nextStep = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setStep(step + 1);
  };

  const addRefs = () => {
    setRefs([...refs, refs.length]);
    let referentsArr = [
      {
        firstName: "",
        lastName: "",
        email: "",
        phone_1: "",
        phone_2: "",
      },
    ];
    refs.map(() => {
      referentsArr.push({
        firstName: "",
        lastName: "",
        email: "",
        phone_1: "",
        phone_2: "",
      });
    });
    setInfo({
      ...info,
      referents: referentsArr,
    });
  };

  const deleteRef = () => {
    refs.pop();
    info.referents.pop();
    setRefs([...refs]);
  };

  const previousStep = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setStep(step - 1);
  };

  const onSubmit = (data) => {
    if (step === 1) {
      setInfo({
        ...info,
        name: data.Name,
        forma_giuridica: data.forma_giuridica,
        partita_iva: data.partita_iva,
        codice_fiscale: data.codice_fiscale,
        place: {
          type: 'external',
          id: data.place
        },
        type: {
          type: 'external',
          id: data.type,
        },
        road: data.Address,
      });
      nextStep();
    } else if (step === 2) {
      let referentsArr = [];
      refs.map((ref) => {
        referentFirst = "referentFirst" + ref;
        referentLast = "referentLast" + ref;
        email = "email" + ref;
        tel1 = "tel1" + ref;
        tel2 = "tel2" + ref;
        referentsArr.push({
          firstName: data[referentFirst],
          lastName: data[referentLast],
          email: data[email],
          phone_1: data[tel1],
          phone_2: data[tel2],
        });
      });
      setInfo({
        ...info,
        referents: referentsArr,
        num_members: data.members,
        note: data.notes,
        additional_infos: {
          feedback: data.feedback,
          ideas: data.ideas,
          cost: data.cost,
          time: data.time,
        },
      });
      nextStep();
    }
  };

  const fetchCities = async (q) => {
    const { data: { items } } = await Api.getPlaces(q);

    const cities = items.map(item => ({ value: item.id, label: item.comune }));
    return cities; 
  };

  const fetchTypes = async (q) => {
    const { data: { items } } = await Api.getTypes(q);

    const types = items.map(item => ({ value: item.id, label: item.description }));
    return types; 
  };

  if (step === 1) {
    return (
      <>
        <GoBack />
        <Body>
          <Container
            isAdd
            setStep={setStep}
            step={step}
            title={"Inserisci i dati anagrafici"}
            content={
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Wrapper>
                    {errors.retry && (
                      <ErrorMessage>{errors.retry.message}</ErrorMessage>
                    )}
                    <Row>
                      <Column>
                        <GrayText>NOME</GrayText>
                        <InputText
                          $isError={!!errors.Name}
                          placeholder={"Inserisci il nome dell'associazione"}
                          name="Name"
                          defaultValue={info.name && info.name}
                          ref={register({
                            required: "* Inserisci un nome per l'associazione",
                          })}
                        />
                        {errors.Name && (
                          <ErrorMessage>{errors.Name.message}</ErrorMessage>
                        )}
                      </Column>
                      <Column>
                        <GrayText>FORMA GIURIDICA</GrayText>
                        <InputSelect onChange={(e) => { e.persist() || setTextFormaGiuridica(e.target.value)} }>
                          {Object.keys(formeGiuridiche).map(index => 
                            (<option key={index} value={index} selected={index === textFormaGiuridica}>{formeGiuridiche[index]}</option>)
                          )}
                        </InputSelect>
                        <Sample
                          name="forma_giuridica"
                          defaultValue={info.forma_giuridica && info.forma_giuridica}
                          ref={register({
                            required: "* Inserisci forma giuridica",
                          })}
                          value={textFormaGiuridica}
                          readOnly
                        />
                        {errors.forma_giuridica && (
                          <ErrorMessage style={{ marginTop: 31 }}>
                            {errors.forma_giuridica.message}
                          </ErrorMessage>
                        )}
                      </Column>
                    </Row>
                    <Row>
                      <Column>
                        <GrayText>PARTITA IVA</GrayText>
                        <InputText
                          $isError={!!errors.partita_iva}
                          placeholder={"Inserisci la partita_iva"}
                          name="partita_iva"
                          defaultValue={info.partita_iva && info.partita_iva}
                          ref={register({})}
                        />
                        {errors.partita_iva && (
                          <ErrorMessage>{errors.partita_iva.message}</ErrorMessage>
                        )}
                      </Column>
                      <Column>
                        <GrayText>CODICE FISCALE</GrayText>
                        <InputText
                          $isError={!!errors.codice_fiscale}
                          placeholder={"Inserisci il codice fiscale"}
                          name="codice_fiscale"
                          defaultValue={info.codice_fiscale && info.codice_fiscale}
                          ref={register({})}
                        />
                        {errors.codice_fiscale && (
                          <ErrorMessage>{errors.codice_fiscale.message}</ErrorMessage>
                        )}
                      </Column>
                    </Row>

                    <Row>
                      <Column>
                        <GrayText>LOCALITÀ</GrayText>
                        <AutocompleteWrapper>
                          <AsyncSelect
                            onChange={(newValue) => setLocation(newValue)}
                            loadOptions={fetchCities}
                            cacheOptions
                            defaultOptions
                            styles={{
                              control: (provided) => ({ ...provided, width: '255px', height: '37px', marginTop: '12.5px' })
                            }}
                          />
                        </AutocompleteWrapper>
                        <Sample
                          name="place"
                          defaultValue={info.place}
                          ref={register({
                            required: "* Inserisci una località per l'associazione",
                          })}
                          value={location?.value}
                          readOnly
                        />
                        {errors.place && (
                          <ErrorMessage style={{ marginTop: 31 }}>
                            {errors.place.message}
                          </ErrorMessage>
                        )}
                      </Column>
                      <Column>
                        <GrayText>TIPO</GrayText>
                        <AutocompleteWrapper>
                          <AsyncSelect
                            onChange={(newValue) => setType(newValue)}
                            loadOptions={fetchTypes}
                            cacheOptions
                            defaultOptions
                            styles={{
                              control: (provided) => ({ ...provided, width: '255px', height: '37px', marginTop: '12.5px' })
                            }}
                          />
                        </AutocompleteWrapper>
                        <Sample
                          name="type"
                          defaultValue={info.type}
                          ref={register({
                            required: "* Inserisci un tipo per l'associazione",
                          })}
                          value={type?.value}
                          readOnly
                        />
                        {errors.type && (
                          <ErrorMessage style={{ marginTop: 31 }}>
                            {errors.type.message}
                          </ErrorMessage>
                        )}
                      </Column>
                      
                    </Row>
                    <Row>
                    <Column>
                        <GrayText>VIA</GrayText>
                        <InputText
                          $isError={!!errors.Address}
                          placeholder={"Nome della strada"}
                          name="Address"
                          defaultValue={info.road && info.road}
                          ref={register({
                            required: "* Inserisci una via per l'associazione",
                          })}
                        />
                        {errors.Address && (
                          <ErrorMessage>{errors.Address.message}</ErrorMessage>
                        )}
                      </Column>
                    </Row>
                    {Object.keys(errors).length !== 0 && <Error />}
                  </Wrapper>
                  <NextWrapper>
                    <Button defaultValue={"Prosegui"} />
                  </NextWrapper>
                </form>
              </>
            }
          ></Container>
        </Body>
      </>
    );
  } else if (step === 2) {
    return (
      <>
        <GoBack step={step} previous={() => previousStep()} />
        <Body>
          <Container
            isAdd
            setStep={setStep}
            step={step}
            title={"Inserisci i dati di contatto"}
            content={
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {refs.map((ref) => {
                    referentFirst = "referentFirst" + ref;
                    referentLast = "referentLast" + ref;
                    email = "email" + ref;
                    tel1 = "tel1" + ref;
                    return (
                      <>
                        <Wrapper>
                          {ref === refs.length - 1 && ref > 0 && (
                            <div onClick={() => deleteRef()}>
                              <Cancel />
                            </div>
                          )}
                          <GrayText>REFERENTE</GrayText>
                          <InputText
                            $isError={!!errors[referentFirst]}
                            placeholder={"Nome del referente"}
                            name={"referentFirst" + ref}
                            defaultValue={
                              info.referents[ref].firstNname &&
                              info.referents[ref].firstNname
                            }
                            ref={register({
                              required: "* Inserisci nome",
                            })}
                          />
                          {errors[referentFirst] && (
                            <ErrorMessage>
                              {errors[referentFirst].message}
                            </ErrorMessage>
                          )}
                          <InputText
                            $isError={!!errors[referentLast]}
                            placeholder={"Cognome del referente"}
                            name={"referentLast" + ref}
                            defaultValue={
                              info.referents[ref].lastName &&
                              info.referents[ref].lastName
                            }
                            ref={register({
                              required: "* Inserisci cognome",
                            })}
                          />
                          {errors[referentLast] && (
                            <ErrorMessage>
                              {errors[referentLast].message}
                            </ErrorMessage>
                          )}
                          <GrayText>Indirizzo email</GrayText>
                          <InputText
                            $isError={!!errors[email]}
                            placeholder={"Inserisci l'indirizzo email"}
                            name={"email" + ref}
                            defaultValue={
                              info.referents[ref].email &&
                              info.referents[ref].email
                            }
                            ref={register({
                              required: "* Inserisci un indirizzo email",
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Formato email errato",
                              },
                            })}
                          />
                          {errors[email] && (
                            <ErrorMessage>{errors[email].message}</ErrorMessage>
                          )}
                          <Row>
                            <Column>
                              <GrayText>TELEFONO 1</GrayText>
                              <InputNumber
                                style={{ width: 100 }}
                                $isError={!!errors[tel1]}
                                placeholder={"Numero 1"}
                                name={"tel1" + ref}
                                pattern="\d*"
                                maxLength={10}
                                title="Sono accettati solo numeri"
                                defaultValue={
                                  info.referents[ref].phone_1 &&
                                  info.referents[ref].phone_1
                                }
                                ref={register({
                                  required: "* Inserisci un numero di telefono",
                                })}
                              />
                              {errors[tel1] && (
                                <ErrorMessage>
                                  {errors[tel1].message}
                                </ErrorMessage>
                              )}
                            </Column>
                            <Column>
                              <GrayText>TELEFONO 2</GrayText>
                              <InputNumber
                                style={{ width: 100 }}
                                $isError={!!errors[tel2]}
                                placeholder={"Numero 2"}
                                name={"tel2" + ref}
                                pattern="\d*"
                                maxLength={10}
                                title="Sono accettati solo numeri"
                                defaultValue={
                                  info.referents[ref].phone_2 &&
                                  info.referents[ref].phone_2
                                }
                                ref={register({
                                  required: "* Inserisci un numero di telefono",
                                })}
                              />
                            </Column>
                          </Row>
                        </Wrapper>
                      </>
                    );
                  })}
                  <Row
                    style={{
                      justifyContent: "flex-end",
                      width: "95%",
                      marginBottom: 19,
                    }}
                    onClick={() => addRefs()}
                  >
                    <RedText>+ aggiungi altri referenti</RedText>
                  </Row>
                  <NextDivisor />
                  <HeaderContainer>
                    <RedRectangle />
                    <TitleContainer>INFORMAZIONI ASSOCIAZIONE</TitleContainer>
                  </HeaderContainer>
                  <Wrapper2>
                    <SecondRow>
                      <GrayText>N°ASSOCIATI ATTIVI</GrayText>
                      <InputNumber
                        placeholder={"n°"}
                        $isError={!!errors.members}
                        name="members"
                        pattern="\d*"
                        title="Sono accettati solo numeri"
                        defaultValue={info.num_members && info.num_members}
                        ref={register({})}
                      />
                    </SecondRow>
                    {errors.members && (
                      <ErrorMessage>{errors.members.message}</ErrorMessage>
                    )}
                    <GrayText>COME CI HAI CONOSCIUTO?</GrayText>
                    <InputTextArea
                      name="feedback"
                      $isError={!!errors.feedback}
                      ref={register({ required: false })}
                      defaultValue={
                        info.additional_infos.feedback &&
                        info.additional_infos.feedback
                      }
                      placeholder={
                        "Attraverso altra associazione, referent agente, internet ecc..."
                      }
                    ></InputTextArea>
                    <GrayText>IDEA / FABBISOGNO / OBBIETTIVO</GrayText>
                    <InputTextArea
                      name="ideas"
                      $isError={!!errors.ideas}
                      ref={register({ required: false })}
                      defaultValue={
                        info.additional_infos.ideas &&
                        info.additional_infos.ideas
                      }
                      placeholder={
                        "Cosa serve all'associazione per crescere?\nCome utilizzerà il contributo?"
                      }
                    ></InputTextArea>
                    <Row>
                      <Column>
                        <GrayText>COSTI REALIZZAZIONE</GrayText>
                        <InputText
                          name="cost"
                          $isError={!!errors.cost}
                          pattern="\d*"
                          title="Sono accettati solo numeri"
                          ref={register({ required: false })}
                          defaultValue={
                            info.additional_infos.cost &&
                            info.additional_infos.cost
                          }
                          placeholder={"€"}
                          right={true}
                          style={{ width: "50%" }}
                        />
                      </Column>
                      <ColumnFix>
                        <GrayText>TEMPI</GrayText>
                        <InputSelect
                          name="time"
                          $isError={!!errors.time}
                          ref={register({ required: false })}
                          defaultValue={
                            info.additional_infos.time
                              ? info.additional_infos.time
                              : "0"
                          }
                          style={{
                            width: 93,
                            height: 41,
                            backgroundImage: "url()",
                            paddingLeft: 26,
                          }}
                        >
                          <option value="0">0 mesi</option>
                          <option value="1">1 mesi</option>
                          <option value="2">2 mesi</option>
                          <option value="3">3 mesi</option>
                          <option value="4">4 mesi</option>
                          <option value="5">5 mesi</option>
                          <option value="6">6 mesi</option>
                          <option value="7">7 mesi</option>
                          <option value="8">8 mesi</option>
                          <option value="9">9 mesi</option>
                          <option value="10">10 mesi</option>
                          <option value="11">11 mesi</option>
                          <option value="12">12 mesi</option>
                          <option value="12+">12+ mesi</option>
                        </InputSelect>
                      </ColumnFix>
                    </Row>
                    <GrayText>NOTE</GrayText>
                    <InputTextArea
                      name="notes"
                      $isError={!!errors.notes}
                      ref={register({ required: false })}
                      defaultValue={info.note && info.note}
                      placeholder={"Scrivi qualcosa di utile"}
                    />
                    {errors.notes && (
                      <ErrorMessage>{errors.notes.message}</ErrorMessage>
                    )}
                    {Object.keys(errors).length !== 0 && <Error />}
                  </Wrapper2>
                  <NextWrapper>
                    <Button value={"Prosegui"} />
                  </NextWrapper>
                </form>
              </>
            }
          ></Container>
        </Body>
      </>
    );
  } else if (step === 3) {
    return (
      <>
        <GoBack step={step} previous={() => previousStep()} />
        <Body>
          <Container
            isAdd
            setStep={setStep}
            shorter
            step={step}
            title={
              "Verifica che i dati siano corretti Non potranno essere modificati"
            }
            content={
              <>
                <Wrapper2>
                  <ShortText>Nome</ShortText>
                  <DescriptionText>{info.name}</DescriptionText>
                  <ShortText>Località</ShortText>
                  <DescriptionText>{location.label}</DescriptionText>
                  <Row>
                    <Column>
                      <ShortText>Via</ShortText>
                      <DescriptionText>{info.road}</DescriptionText>
                    </Column>
                  </Row>
                  <ShortText>Referente</ShortText>
                  <DescriptionText>{info.referents[0].firstName} {info.referents[0].lastName}</DescriptionText>
                  <Row>
                    <Column>
                      <ShortText>Telefono 1</ShortText>
                      <DescriptionText>
                        {info.referents[0].phone_1}
                      </DescriptionText>
                    </Column>
                    <Column>
                      <ShortText>Telefono 2</ShortText>
                      <DescriptionText>
                        {info.referents[0].phone_2}
                      </DescriptionText>
                    </Column>
                  </Row>
                  <ShortText>E-mail</ShortText>
                  <DescriptionText style={{width: 250, overflow: 'auto'}}>{info.referents[0].email}</DescriptionText>
                  <ShortText>NOTE</ShortText>
                  <DescriptionText>{info.note}</DescriptionText>
                  <ShortText>Soci Attivi</ShortText>
                  <DescriptionText>{info.num_members}</DescriptionText>
                  <ShortText>Come ci ha conosciuto</ShortText>
                  <DescriptionText>
                    {info.additional_infos.feedback}
                  </DescriptionText>
                  <ShortText>Idea / Fabbisogno / Obbiettivo</ShortText>
                  <DescriptionText>
                    {info.additional_infos.ideas}
                  </DescriptionText>
                  <Row short>
                    <Column>
                      <ShortText>Costi</ShortText>
                      <DescriptionText>
                        {info.additional_infos.cost}€
                      </DescriptionText>
                    </Column>
                    <Column>
                      <ShortText>Tempi</ShortText>
                      <DescriptionText>
                        {info.additional_infos.time} mesi
                      </DescriptionText>
                    </Column>
                  </Row>
                </Wrapper2>
                <NextWrapper onClick={() => createLocation()}>
                  <Button disabled={isSubmitting} value={"Conferma"} />
                </NextWrapper>
              </>
            }
          ></Container>
        </Body>
      </>
    );
  }
}

export default AddAssociation;
