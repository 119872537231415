import React from "react";
import Slider from "infinite-react-carousel";
import {
  ProfilePic,
  NoImg,
  AddImageText,
  AddImageWrapper,
  AddPropic,
} from "../screens/association_screen/association.style";

function Carousel(props) {
  const { photos: _photos, changePic, id, location_id } = props;

  const photos = [..._photos, { isUploader: true }];

  if (photos.length === 0 && id !== location_id) {
    return <></>;
  } else if (photos.length === 0 && id === location_id) {
    return (
      <div style={{ marginTop: 20 }}>
        <Slider dots arrows={false} dotsClass={"custom-dots"}>
          <div>
            <ProfilePic>
              <div onClick={() => changePic.current.click()}>
                <AddImageWrapper>
                  <AddImageText>Aggi. Immagini</AddImageText>
                  <div style={{ marginLeft: 6 }}>
                    <AddPropic />
                  </div>
                </AddImageWrapper>
                <NoImg />
              </div>
            </ProfilePic>
          </div>
        </Slider>
      </div>
    );
  } else if (photos.length > 0 && id !== location_id) {
    return (
      <div style={{ marginTop: 20 }}>
        <Slider dots arrows={false} dotsClass={"custom-dots"}>
          {photos.map((photo) => (
            <div key={photo}>
              <ProfilePic url={"https://admin.ipoh.app/" + photo} />
            </div>
          ))}
        </Slider>
      </div>
    );
  } else if (photos.length > 0 && id === location_id) {
    return (
      <div style={{ marginTop: 20 }}>
        <Slider dots arrows={false} dotsClass={"custom-dots"}>
          {photos.map((photo) => (
            <>
              {photo.isUploader ? (
                <>
                  <div key={photo}>
                    <ProfilePic>
                      <div onClick={() => changePic.current.click()}>
                        <AddImageWrapper>
                          <AddImageText>Aggi. Immagini</AddImageText>
                          <div style={{ marginLeft: 6 }}>
                            <AddPropic />
                          </div>
                        </AddImageWrapper>
                        <NoImg />
                      </div>
                    </ProfilePic>
                  </div>
                </>
              ) : (
                <ProfilePic url={"https://admin.ipoh.app/" + photo} />
              )}
            </>
          ))}
        </Slider>
      </div>
    );
  }
}

export default Carousel;
