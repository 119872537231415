import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Api from '../../services/api.service';

import GoBack from "../../components/goback.component";
import LeaderboardRows from '../../components/leaderboard-rows';

import {
  Header,
  Conteiner
} from './leaderboard.style';

function LeaderboardScreen() {
  const [leaderboard, setLeaderboard] = useState([]);
  const { type, interval, final } = useParams();
  const isFinal = final === 'final';

  const fetchLeaderboard = useCallback(async () => {
    const dateMin = moment().startOf(interval);
    const dateMax = moment().endOf(interval);

    let resp;
    switch (type) {
      case 'association': resp = await Api.leaderboardLocations(dateMin, dateMax); break;
      case 'manifestation': resp = await Api.leaderboardEvents(dateMin, dateMax); break;
      default: throw new Error('Tipo leaderboard sconosciuto: ' + type);
    }

    setLeaderboard(resp.data.leaderboard);
  }, [type, interval, setLeaderboard]);

  useEffect(() => {
    fetchLeaderboard();
  }, [fetchLeaderboard]);

  return (
    <div>
      <GoBack />
      <Header 
        type={type} 
        formattedDate={moment().format(interval === 'month' ? 'MMMM YYYY' : 'YYYY')}
        leaderboard={isFinal ? leaderboard : undefined}
      />
      <Conteiner>
        <LeaderboardRows leaderboard={leaderboard.slice(isFinal ? 3 : 0)} />
      </Conteiner>
    </div>
  );
}

export default LeaderboardScreen;
