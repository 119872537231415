import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled, { css } from 'styled-components';

import {ReactComponent as RedXSvg} from '../../assets/red-x.svg';
import {ReactComponent as PenSvg} from '../../assets/calendar-pen.svg';

export const Wrapper = styled.div``;

export const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: auto;
  border-left: solid #DFE3E7 1px;
  border-bottom: solid #DFE3E7 1px;
`;

export const CalendarCell = ({ day, manifestations=[], disabled, selected, onClick, disabledStyle=false }) => (
  <CalendarCellWrapper {...{ disabled, onClick }} style={selected ? { backgroundColor: '#DFE3E7' } : {} }>
    {manifestations.length > 0 && <ManifestationCountWrapper>
      <ManifestationCountText>{manifestations.length}</ManifestationCountText>
    </ManifestationCountWrapper>}
    <CalendarCellNumber style={disabled || disabledStyle ? { opacity: 0.4 } : {}} isRed={day.isoWeekday() === 7}>
      {day.format('D')}
    </CalendarCellNumber>
    <ManifestationsWrapper>
      {manifestations.slice(0, 4).map(m => {
        const { id: key, state } = m;
        return <ManifestationStateIndicator {...{ key, state, style: { margin: 0, placeSelf: 'center' } }} />;
      })}
      {manifestations.length < 4 && [...Array(4 - manifestations.length)].map((_, i) => (
        <div key={i} style={{ height: 12, width: 12 }} />
      ))}
    </ManifestationsWrapper>
  </CalendarCellWrapper>
);

const CalendarCellWrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 110px;
  padding: 12px 0 4px 0;
  border: none;
  background-color: transparent;
  border-right: solid #DFE3E7 1px;
  border-top: solid #DFE3E7 1px;
`;

const ManifestationCountWrapper = styled.div`
  margin-bottom: 20px;
  padding: 2px 10px;
  background-color: #B40000;
  border-radius: 10px;
`;

const ManifestationCountText = styled.p`
  font-size: 13px;
  color: #FFFFFF;
`;

const CalendarCellNumber = styled.p`
  font-size: 18px;
  text-align: center;
  ${p => p.isRed && css`
    color: #B40000;
  `}
`;

const ManifestationsWrapper = styled.div`
  margin-top: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 4px;
  grid-column-gap: 5px;
`;

export const MonthYearText = styled.p`
  font-size: 18px;
  color: #505050;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const WeekdaysRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 6px 0;
`;

export const CalendarInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px 10px;
  grid-column: 1 / 8;
  background-color: #555555;
  border-right: solid #DFE3E7 1px;
  border-top: solid #DFE3E7 1px;
  transition: max-height 200ms, padding-top 200ms, padding-bottom 200ms;
`;

export const CalendarInfo = ({ manifestation: { state, date, location }, onEdit, textColor, margin }) => {
  const history = useHistory();

  function openAssociation() {
    if (location) {
      history.push({
        pathname: '/association',
        state: location.id
      });
    }
  }
  return (
    <CalendarInfoWrapper style={{ margin }}>
      <ManifestationStateIndicator state={state} />
      <CalendarInfoText style={{ color: textColor, cursor: 'pointer' }} onClick={openAssociation}>
        {moment(date, 'DD/MM/YYYY HH:mm:ss').format('HH:mm')} - {location && location.name}
      </CalendarInfoText>
      {/* {(state === 'confirmed') && <div>
        <PenButton onClick={onEdit}><PenSvg /></PenButton>
      </div>} */}
    </CalendarInfoWrapper>
  );
}

const CalendarInfoWrapper = styled.div`
  margin: 12px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const CalendarInfoText = styled.p`
  color: #FFFFFF;
  max-width: 80%;
  word-break: break-all;
`;

const PenButton = styled.button`
  position: absolute;
  margin-top: -8px;
  margin-left: 5px;
  background-color: transparent;
  border: none;
`;

export const Weekday = ({ day, isRed }) => (
  <WeekdayWrapper>
    <WeekdayText isRed={isRed}>{day}</WeekdayText>
  </WeekdayWrapper>
);

const WeekdayWrapper = styled.div`
  flex: 1;
`;

const WeekdayText = styled.p`
  ${p => p.isRed && css`
    color: #B40000;
  `}
  text-align: center;
`;

export const SummaryWrapper = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Row = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: row;
`;

export const ManifestationStateIndicator = ({ state, style }) => {
  switch (state) {
    case 'concluded': return <Circle style={style} color='#39B54A' />;
    case 'shifted': return <Circle style={style} color='#F9C82D' />;
    case 'confirmed': return <Circle style={style} color='#F44E14' />;
    default: return <RedX style={style} />;
  }
};

const Circle = styled.div`
  ${p => p.dim ? css`
    height: ${p => p.dim}px;
    width: ${p => p.dim}px;
    border-radius: ${p => (p.dim+1)/2}px;
  ` : css`
    height: 12px;
    width: 12px;
    border-radius: 7px;
  `}
  background-color: ${p => p.color};
  margin: -2px 5px 0 5px;
`;

const RedX = styled(RedXSvg)`
  margin: 0 5px;
`;
