import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import Api from '../../services/api.service';
import AppContext from '../../services/context.service';

import AppCalendar from '../../components/app-calendar/app-calendar.component';
import {ReactComponent as InboxSvg} from '../../assets/inbox_done_round.svg';

import {
  PageWrapper,
  PageTitle,
  PageConteiner,
  AddButton,
  ButtonsRow,
  ButtonsWrapper,
  Button,
  Arrow
} from './calendar.style';

function CalendarScreen(props) {
  const { user, openManifestationModal } = useContext(AppContext);
  const [calendarType, setCalendarType] = useState('monthly');
  const [date, setDate] = useState(moment());
  const [profile, setProfile] = useState({});
  const { id } = useParams();
  const history = useHistory();
  const calendarRef = useRef();

  function onRightArrow() {
    const ref = calendarRef.current;
    if (!ref) return;
    switch (calendarType) {
      case 'monthly': ref.nextMonth(); break;
      case 'weekly': ref.nextWeek(); break;
      case 'daily': ref.nextDay(); break;
      default: console.warn('Unknown calendar type'); break;
    }
  }

  function onLeftArrow() {
    const ref = calendarRef.current;
    if (!ref) return;
    switch (calendarType) {
      case 'monthly': ref.previousMonth(); break;
      case 'weekly': ref.previousWeek(); break;
      case 'daily': ref.previousDay(); break;
      default: console.warn('Unknown calendar type'); break;
    }
  }

  function onSubmit(state) {
    if (state === 'concluded') {
      history.push('/completed/manifestation');
    } else {
      calendarRef.current && calendarRef.current.fetchManifestations();
    }
  }

  function onAdd() {
    openManifestationModal({ create: true, date }, onSubmit);
  }

  function onEdit(data) {
    openManifestationModal(data, onSubmit);
  }

  useEffect(() => {
    if (props.location.state) {
      calendarRef.current && calendarRef.current.fetchManifestations();
    }
  }, [props.location]);
  
  useEffect(() => {
    if (id !== 'self') Api.getProfile(id).then(({ data }) => setProfile(data.user));
  }, [id]);

  return (
    <PageWrapper>
      {id === 'self' ? <PageTitle>Calendario</PageTitle>: <>
        <PageTitle>Calendario di</PageTitle>
        <PageTitle>{profile.name}</PageTitle>
      </>}

      <PageConteiner>
        <ButtonsRow>
          <ButtonsWrapper>
            <Button left onClick={onLeftArrow}><Arrow /></Button>
            <Button isGray onClick={() => calendarRef.current && calendarRef.current.selectDay(moment())}><InboxSvg /></Button>
            <Button right onClick={onRightArrow}><Arrow style={{ transform: 'rotate(180deg) scale(1.2)' }} /></Button>
          </ButtonsWrapper>
          <ButtonsWrapper>
            <Button isGray={calendarType === 'monthly'} left onClick={() => setCalendarType('monthly')}>30d</Button>
            <Button isGray={calendarType === 'weekly'} onClick={() => setCalendarType('weekly')}>7d</Button>
            <Button isGray={calendarType === 'daily'} right onClick={() => setCalendarType('daily')}>1d</Button>
          </ButtonsWrapper>
        </ButtonsRow>

        <AppCalendar 
          ref={calendarRef}
          userId={id === 'self' ? user.id : id}
          type={calendarType}
          date={date} 
          onChange={day => setDate(day)}
          onManifestationEdit={onEdit}
        />
      </PageConteiner>
      {id === 'self' && <AddButton onClick={onAdd}>+ Aggiungi Manifestazione</AddButton>}
    </PageWrapper>
  );
}

export default CalendarScreen;
