import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as FirstPlaceIcon } from "../../assets/roh-first-place.svg";
import { ReactComponent as SecondPlaceIcon } from "../../assets/roh-second-place.svg";
import { ReactComponent as ThirdPlaceIcon } from "../../assets/roh-third-place.svg";

const LeaderboardRows = ({ leaderboard, showIcons }) => {
  const history = useHistory();
  return leaderboard.map((l, index) => {
    try {
      const {
        total,
        created_by: { id, name, user_pic_thumbnail },
      } = l;
      const position = index + 1;
      function openProfile(id) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        history.push({ pathname: "/profile", state: id });
      }

      return (
        <Row key={id} onClick={() => openProfile(id)}>
          <div style={{ width: 27 }}>
            <NumberText>{position}.</NumberText>
          </div>
          <div style={{ width: 43 }}>
            <div
              style={{
                width: 41,
                height: 41,
                borderRadius: 100,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#b40000",
                backgroundImage: `url(${
                  "https://admin.ipoh.app/" + user_pic_thumbnail
                })`,
                backgroundSize: "cover",
              }}
            />
          </div>
          <Text margin="0 20px 0 19px">{name}</Text>
          <p style={{ marginLeft: "auto" }}>pt.{total}</p>
          {showIcons && (
            <IconWrapper>
              {position === 1 && <FirstPlaceIcon />}
              {position === 2 && <SecondPlaceIcon />}
              {position === 3 && <ThirdPlaceIcon />}
            </IconWrapper>
          )}
        </Row>
      );
    } catch (e) {
      console.log(
        "LeaderboardRows - warning: skipping bad data at index " + index
      );
    }
  });
};

const Row = styled.button`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 11px 20px;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  border-bottom: solid 1px #dfe3e7;
  cursor: pointer;
  @media (max-width: 375px) {
    padding: 11px 10px;
  }
  @media (max-width: 320px) {
    padding: 11px 5px;
  }
`;

const NumberText = styled.p`
  width: 27px;
  font-size: 19px;
  color: #000000;
`;

const Text = styled.p`
  text-align: left;
  font-size: 16px;
  color: #000000;
  margin: ${(p) => p.margin};
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
`;

export default LeaderboardRows;
