import styled from "styled-components";

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  padding-top: 30px;
  background-color: #F2F3F8;
  justify-content: center;
  align-items: center;
`;

export const RedBand = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #b40000;
  padding-top: 16px;
  padding-bottom: 22px;
`;

export const RedBandText = styled.p`
  font-size: 25px;
  color: white;
`;

export const RedBandSubText = styled.p`
  font-size: 12px;
  color: white;
  margin-top: 10px;
`;
