import React from "react";
import styled from "styled-components";

import { ReactComponent as FirstPlaceIcon } from "../../assets/first-place.svg";
import { ReactComponent as SecondPlaceIcon } from "../../assets/second-place.svg";
import { ReactComponent as ThirdPlaceIcon } from "../../assets/third-place.svg";

export const Header = ({ type, formattedDate, leaderboard }) => (
  <HeaderWrapper>
    {leaderboard !== undefined && <p>CLASSIFICA FINALE</p>}
    <h1>
      {type === "association"
        ? "Associazioni Registrate"
        : "Manifestazioni Effettuate"}
    </h1>
    <h1>{formattedDate}</h1>
    {leaderboard !== undefined && (
      <Row>
        <Classificato
          position={2}
          data={leaderboard.length >= 2 ? leaderboard[1] : null}
        />
        <Classificato
          position={1}
          data={leaderboard.length >= 1 ? leaderboard[0] : null}
        />
        <Classificato
          position={3}
          data={leaderboard.length >= 3 ? leaderboard[2] : null}
        />
      </Row>
    )}
  </HeaderWrapper>
);

const HeaderWrapper = styled.div`
  padding: 17px 0;
  background-color: #b40000;
  text-align: center;
  & p {
    font-size: 16px;
    color: #fcee21;
    margin-bottom: 5px;
  }
  & h1 {
    font-size: 27px;
    color: #ffffff;
    text-transform: capitalize;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
`;

const Classificato = ({ position, data }) => {
  return (
    <ClassificatoWrapper>
      {data !== null && (
        <>
          <div
            style={{
              width: position === 1 ? 108 : 64,
              height: position === 1 ? 108 : 64,
              borderRadius: 100,
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: "#b40000",
              backgroundImage: `url(${data.created_by.user_pic_thumbnail})`,
              backgroundSize: "cover",
            }}
          />
          <IconWrapper>
            <PlaceIcon pos={position} />
          </IconWrapper>
          <p style={{ fontSize: position === 1 ? 19 : 16 }}>
            {data.created_by.name}
          </p>
          <p>pt.{data.total}</p>
        </>
      )}
    </ClassificatoWrapper>
  );
};

const PlaceIcon = ({ pos }) => {
  switch (pos) {
    case 1:
      return <FirstPlaceIcon />;
    case 2:
      return <SecondPlaceIcon />;
    case 3:
      return <ThirdPlaceIcon />;
    default:
      return null;
  }
};

const ClassificatoWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & p {
    font-size: 12px;
    word-break: break-all;
    color: #f2f3f8;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -60%);
`;

export const Conteiner = styled.div`
  margin: 24px auto;
  max-width: 95vw;
`;
