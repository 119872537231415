import React from "react";
import {
  Wrapper,
  Title,
  ContentWrapper,
  DescriptionText
} from "./reward.component.style.js";
import RewardIcon from "./reward_icon.component.js";
import ProgressBar from "./progressbar.component";

function Reward(props) {
  const { icon, title, description, exp, isExp, windowMode, locked } = props;

  if (description) {
    return (
      <Wrapper>
        <RewardIcon icon={icon} locked={locked} />
        <ContentWrapper>
          <Title>{title}</Title>
          <DescriptionText
            style={{ marginBottom: description.length > 36 ? 10 : 22 }}
          >
            {description}
          </DescriptionText>
          {isExp && <ProgressBar width={exp > 100 ? 100 : exp + "%"} isSmall windowMode={windowMode} />}
        </ContentWrapper>
      </Wrapper>
    );
  } else {
    return <RewardIcon icon={icon} />;
  }
}

export default Reward;
