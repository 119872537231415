import React from "react";
import styled from "styled-components";
import { ReactComponent as Point } from "../assets/medal.svg";
import { ReactComponent as WhitePencil } from "../assets/white-edit.svg";
import { ReactComponent as GrayPencil } from "../assets/gray-edit.svg";
import { ReactComponent as WhiteProfile } from "../assets/white-profile.svg";
import { ReactComponent as GrayProfile } from "../assets/gray-profile.svg";
import { ReactComponent as WhiteDone } from "../assets/white-done.svg";
import { ReactComponent as GrayDone } from "../assets/gray-done.svg";

export const Medal = () => {
  return <Point width={12} height={20} />;
};

export const Edit = (props) => {
  if (props.isActive === true) {
    return <WhitePencil width={20} height={20} />;
  } else {
    return <GrayPencil width={20} height={20} />;
  }
};

export const Profile = (props) => {
  if (props.isActive === true) {
    return <WhiteProfile width={20} height={20} />;
  } else {
    return <GrayProfile width={20} height={20} />;
  }
};

export const Done = (props) => {
  if (props.isActive === true) {
    return <WhiteDone width={20} height={20} />;
  } else {
    return <GrayDone width={20} height={20} />;
  }
};

export const Wrapper = styled.div`
  height: auto;
  background-color: #fff;
  border-radius: 18px;
  margin-bottom: 18px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 10px 10px;
  padding-top: 21px;
  width: 100%;
  @media (min-width: 768px) {
    width: 680px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  margin-left: 40px;
  margin-bottom: 43px;
  margin-top: 11px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 51px;
  height: 51px;
  border-radius: 100px;
  background-color: ${(props) => (props.isActive ? "#B40000" : "#F2F3F8")};
  cursor: pointer;
`;

export const RedRectangle = styled.div`
  width: 5px;
  height: 33px;
  background-color: #b40000;
`;

export const TitleContainer = styled.p`
  font-size: 16px;
  color: black;
  margin-left: 13px;
  flex-grow: 1;
`;

export const TitleContainerAdd = styled.p`
  font-size: 16px;
  color: black;
  margin-left: ${(props) => (props.shorter ? "17px" : "49px")};
  flex-grow: 1;
  word-wrap: break-word;
  width: 100%;
`;

export const Time = styled.p`
  font-size: 12px;
  color: #808080;
  margin-right: 15px;
`;

export const LevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  padding-bottom: 20px;
`;

export const StatsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
`;

export const MonthText = styled.p`
  font-size: 12px;
  color: ${(props) => (props.active ? "#b40000" : "#808080")};
  cursor: pointer;
`;

export const YearText = styled.p`
  font-size: 12px;
  color: ${(props) => (props.active ? "#b40000" : "#808080")};
  cursor: pointer;
`;

export const LevelText = styled.p`
  margin-top: 5px;
  font-size: 12px;
  color: #b40000;
`;
