import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import Api from '../../services/api.service';
import AppContext from '../../services/context.service';

import { normalizeTimeString, validateTime } from '../../services/utils.service';

import Autocomplete from '../autocomplete';
import Select from '../select';
import TimeInput from '../time-input';
import TextInput from '../text-input';
import NumberInput from '../numer-input';

import LoadingSpinner from '../loading-spinner.component';

import {
  Content,
  Conteiner,
  FlexRow,
  Line,
  Title,
  Label,
  SubTitle,
  MoneyField,
  ConfirmButton,
  MoneySummaryWrapper,
  PairFieldWrapper,
  DateField,
  TextField,
  CheckBoxField,
  PlusButton,
  ErrorMessage
} from './manifestation-modal.style';

import AsyncSelect from 'react-select/async';

const roomTypeOptions = [
  { value: 'parish', label: 'parrocchiale' },
  { value: 'municipal', label: 'municipale' },
  { value: 'private', label: 'privata' },
  { value: 'association headquarters', label: 'sede associazione' },
  { value: 'other', label: 'altro' }
];

function ManifestationCreateForm({ data: { date, id, label, ...data }, onSubmit, closeForm }) {
  const { user, saveManifestationModalState } = useContext(AppContext);
  const history = useHistory();
  const { errors, control, handleSubmit, watch, setError } = useForm({
    defaultValues: {
      manifestation_date: data.manifestation_date || date,
      association: id ? { value: id, label: label } : '',
      room_type: data.room_type || '',
      invite_time: data.invite_time || '20:00',
      start_time: data.start_time ||'20:30',
      hasNewAddr: data.hasNewAddr || { value: true },
      tot_contribution: data.tot_contribution || '400.00',
      num_couples: data.num_couples || '20',
      iban: data.iban || '',
      fiscal_code: data.fiscal_code || '',
      note: data.note || '',
      location: data.location || null,
      province: data.province || null,
      cap: data.cap || '',
      via: data.via || '',
      civic_number: data.civic_number || ''
    },
    mode: 'onSubmit'
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const num_couples = +watch('num_couples');
  const insertAddress = !watch('hasNewAddr').value;
  const startTime = watch('start_time');

  async function submit(formData) {
    const start_time = normalizeTimeString(formData.start_time);
    let newAddr;
    const errors = [];
    if (!validateTime(start_time)) errors.push({ name: 'start_time', message: 'Orario inizio non valido' });
    if (errors.length > 0) return setError(errors);

    if (!formData.hasNewAddr.value) {
      newAddr = JSON.stringify({
        place: {
          type: 'external',
          id: formData.location.value
        },
        road: formData.via,
      });
    }

    try {
      setIsSubmitted(true);
      const payload = {
        location_id: formData.association.value,
        date: `${formData.manifestation_date.format('DD/MM/YYYY')} ${start_time}:00`,
        num_couples: +formData.num_couples,
        tot_contribution: +formData.tot_contribution,
        room_type: formData.room_type.value,
        iban: formData.iban,
        fiscal_code: formData.fiscal_code,
        note: formData.note,
        change_address: newAddr
      };
      if (formData.partita_iva) {
        payload.partita_iva = formData.partita_iva;
      }
      if (formData.codice_fiscale) {
        payload.codice_fiscale = formData.codice_fiscale;
      }
      await Api.createEvent(payload);
      onSubmit('confirmed');
    } catch (e) {

      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      const res = e.response;

      console.log('RESPONSE', e)
      if (res) {
        if (res.status >= 500 && res.status <= 599) {
          setError('general', 'unknown', 'Errore interno, riprovare più tardi!');
        } else if (typeof res.data === "string") {
          setError('general', 'unknown', res.data);
          setIsSubmitted(false);
        } else {
          setError('general', 'unknown', `È stato riscontrato un errore sconosciuto! ${JSON.stringify(res)}`);
        }
      } else {
        setError('general', 'unknown', `È stato riscontrato un errore sconosciuto! ${JSON.stringify(e)}`);
      }
      setIsSubmitted(false);
    }
  }

  function onCreateAssociation() {
    saveManifestationModalState(watch());
    closeForm();
    history.push({
      pathname: '/addassociation',
      state: 'create-manifestation-step'
    });
  }

  const { value: locationId } = watch('association');

  const [fiscalDataEmpty, setFiscalDataEmpty] = useState(false);

  const processLocation = useCallback(async (locationId) => {
    const { data } = await Api.getLocation(locationId);
    const { partita_iva, codice_fiscale } = data;
    if (!partita_iva && !codice_fiscale) {
      setFiscalDataEmpty(true);
    }
  }, []);

  useEffect(() => {
    if (!locationId) {
      return;
    }
    processLocation(locationId)
  }, [locationId, processLocation]);

  const fetchCities = async (q) => {
    const { data: { items } } = await Api.getPlaces(q);

    const cities = items.map(item => ({ value: item.id, label: item.comune }));
    return cities; 
  };
  
  return (
    <Content closeForm={closeForm}>
      {document.body.clientWidth < 360 ? (<>
        <Title style={{ marginBottom: 3 }}>Programma</Title>
        <Title>Manifestazione</Title>
      </>): <Title>Programma Manifestazione</Title>}
      {errors.general && <ErrorMessage style={{ margin: '15px 12px 0 12px', textAlign: 'center' }}>
        {errors.general.message}
      </ErrorMessage>}
      <Line />

      <form onSubmit={handleSubmit(submit)}>
        <Conteiner>
          <FlexRow style={{ marginBottom: 20, alignItems: 'stretch' }}>
            <Controller
              name='manifestation_date'
              as={<DateField label='DATA MANIFESTAZIONE' />}
              control={control} 
            />
            <TextField label='DATA ACCORDO' text={moment().format('DD MM YYYY')} />
          </FlexRow>

          <Label style={{ marginTop: 20, marginBottom: 13 }}>ASSOCIAZIONE</Label>
          <FlexRow style={{ marginBottom: 20 }}>
            <div style={{ flex: 1, marginRight: 13 }}>
              <Controller
                name="association"
                as={
                  <Autocomplete
                    searchFunction={async (value) => {
                      const { data } = await Api.searchLocationByName(value, 1, user.id);
                      return data.data.map(item => ({ value: item.id, label: item.name }));
                    }}
                    highlightError={!!errors.association}
                    noOptionsMessage={'Nessuna associazione trovata'}
                  />}
                rules={{ required: true }}
                control={control}
              />
            </div>
            <PlusButton onClick={onCreateAssociation} disabled={!!locationId} />
          </FlexRow>
          {errors.association && (
            <ErrorMessage>{errors.association.type === 'required' ? 'Campo obbligatorio' : errors.association.message}</ErrorMessage>
          )}
          </Conteiner>
          <Conteiner>
            <FlexRow>
              <TextField label='ORARIO INVITI' style={{lineHeight: '90px', marginTop: '10px'}} text={moment(startTime, 'HH:mm').subtract(30, 'minutes').format('HH:mm')} />
              <Controller 
                name="start_time"
                as={<TimeInput highlightError={!!errors.start_time} label='ORARIO INIZIO' />}
                rules={{ required: true, pattern: /\d{1,2}:\d{1,2}/ }}
                control={control} 
              />
            </FlexRow>

            <Controller 
              name="hasNewAddr"
              as={<CheckBoxField 
                label='l’indirizzo dell’associazione ed il luogo della manifestazione sono gli stessi' 
                wrapperStyle={{ marginTop: 29 }} 
              />}
              control={control}
            />

            {insertAddress && <>
              <Label style={{ marginTop: 20, marginBottom: 13 }}>LOCALITÀ</Label>
              <Controller 
                name="location"
                as={<AsyncSelect
                  loadOptions={fetchCities}
                  cacheOptions
                  defaultOptions
                  styles={{
                    control: (provided) => ({ ...provided, width: '255px', height: '37px', marginTop: '12.5px' })
                  }}
                />}
                rules={{ required: true }}
                control={control}
              />

              <FlexRow style={{ marginTop: 20 }}>
                <Controller 
                  name='via'
                  placeholder='Inserisci la via'
                  as={<TextInput label='VIA' error={errors.via} showErrorMessage={false} wrapperStyle={{ flexGrow: 1, marginRight: 15 }} />} 
                  rules={{ required: true }}
                  control={control}  
                />
              </FlexRow>
            </>}

            <Controller
              name="room_type"
              as={<Select 
                label='TIPOLOGIA SALA' 
                error={errors.room_type}
                options={roomTypeOptions} 
                wrapperStyle={{ marginTop: 20 }} 
              />}
              rules={{ required: true }}
              control={control}
            />

        <Line style={{ margin: '30px 0' }} />
        </Conteiner>
        <SubTitle>DESCRIZIONE CONTRIBUTO</SubTitle>
        <Conteiner>
          <MoneySummaryWrapper>
            <PairFieldWrapper>
              <Label style={{ marginRight: 6 }}>CON</Label>
              <Controller
                name='num_couples'
                as={<NumberInput highlightError={!!errors.num_couples} onlyPositive digitLimit={6} />}
                rules={{ required: true }}
                control={control}
              />
              <Label style={{ marginLeft: 6 }}>COPPIE</Label>
            </PairFieldWrapper>

            <div>
              <Controller
                name='tot_contribution'
                as={<MoneyField highlightError={!!errors.tot_contribution} label='TOT CONTRIBUTO' isInput />}
                rules={{ required: true }}
                control={control}
              />
              <div style={{ marginTop: 18 }} />
              <MoneyField label='TOT A COPPIA' value={num_couples === 0 ? '0' : ''+(+watch('tot_contribution') / num_couples)?.toFixed(2)} />
            </div>
          </MoneySummaryWrapper>

          {fiscalDataEmpty &&
          <>
            <Controller 
              name='partita_iva'
              as={<TextInput label='Partita IVA' error={errors.partita_iva} wrapperStyle={textInputWrapperStyle} />} 
              control={control}  
            />
            <Controller 
              name='codice_fiscale'
              as={<TextInput label='Codice Fiscale' error={errors.codice_fiscale} wrapperStyle={textInputWrapperStyle} />} 
              control={control}  
            />
          </>}

          <Controller 
            name='iban'
            as={<TextInput label='IBAN' error={errors.iban} wrapperStyle={textInputWrapperStyle} />} 
            control={control}  
          />
          {/* <Controller 
            name='fiscal_code' 
            as={<TextInput label='CODICE FISCALE / PARTITA IVA' error={errors.fiscal_code} wrapperStyle={textInputWrapperStyle} />}
            rules={{ 
              required: true, 
              pattern: {
                // regex codice fiscale
                // value: /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i,
                // regex codice fiscale (semplice)
                // value: /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/,
                // regex partita iva (solo numeri, 11 caratteri)
                // value: /^[0-9]{11}$/,
                // this regex should match both italian fiscal code and vat code (11 chars, all digits)
                value: /^[a-zA-Z0-9]{6}[0-9]{2}[a-zA-Z|0-9]{3}[a-zA-Z]{0,1}[0-9]{0,3}[a-zA-Z|0-9]{0,1}$/,
                message: 'Codice fiscale non valido'
              }
            }}
            control={control}
          /> */}
          <Controller 
            name='note' 
            placeholder='Scrivi qualcosa di utile'
            as={<TextInput label='NOTE' wrapperStyle={textInputWrapperStyle} />}
            control={control}  
          />
          {isSubmitted && <LoadingSpinner/>}
          {!isSubmitted && <ConfirmButton>Conferma</ConfirmButton>}
          </Conteiner>
      </form>
    </Content>
  );
}

const textInputWrapperStyle = {
  margin: '15px 0'
};

export default ManifestationCreateForm;
