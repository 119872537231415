import React from 'react';
import styled from 'styled-components';

export const PageConteiner = styled.div`
  margin: 16px 27px;
  min-width: 315px;
  max-width: 355px;
  background-color: #fff;
  border-radius: 18px;
  margin-bottom: 18px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 10px 10px;
  padding: 20px 0 35px 0;
`;

export const TitleRow = ({ label, button, onClick }) => (
  <TitleRowWrapper>
    <TitleText>{label}</TitleText>
    <Button onClick={onClick} color={button.color}>{button.text}</Button>
    <LeftRedRect />
  </TitleRowWrapper>
);

const TitleRowWrapper = styled.div`
  position: relative;
  padding: 5px 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TitleText = styled.h1`
  margin-left: 16px;
  font-size: 19px;
  color: #000000;
`;

const Button = styled.button`
  font-size: 16px;
  padding: 9px 4px;
  background-color: ${p => p.color};
  color: #FFFFFF;
  border-radius: 4px;
  border: none;
`;

const LeftRedRect = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #B40000;
`;

export const Label = styled.p`
  font-size: 14px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SubTitle = (props) => (
  <div style={{ position: 'relative' }}>
    <SubTitleText {...props} />
    <LeftRedRect />
  </div>
);

const SubTitleText = styled.h2`
  padding: 9px 0;
  font-size: 18px;
  color: #505050;
  text-align: center;
`;

export const Line = styled.hr`
  background-color: #DFE3E7;
  border: none;
  height: 1px;
  margin: 22px 0;
`;

export const Conteiner = styled.div`
  padding: 0 24px;
`;

export const TextField = ({ label, text, textStyle }) => (
  <div>
    <Label>{label}</Label>
    <Label 
      style={{ color: '#000000', margin: '4px 0 18px 0', ...textStyle }}
    >{text}</Label>
  </div>
);

export const LoadingText = styled.p.attrs({
  children: 'Caricamento in corso...'
})`
  margin: 20px 0 0 24px;
  font-size: 18px;
`;
