import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import Api from '../../services/api.service';

import GoBack from "../../components/goback.component";
import LeaderboardRows from '../../components/leaderboard-rows';

import {
  Header,
  Conteiner
} from './roll-of-honor.style';

function RollOfHonor() {
  const [type, setType] = useState('manifestation');
  const [date, setDate] = useState(moment().subtract(1, 'months'));
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchLeaderboard = useCallback(async () => {
    const dateMin = moment(date).startOf('month');
    const dateMax = moment(date).endOf('month');

    let resp;
    switch (type) {
      case 'association': resp = await Api.leaderboardLocations(dateMin, dateMax); break;
      case 'manifestation': resp = await Api.leaderboardEvents(dateMin, dateMax); break;
      default: throw new Error('Tipo leaderboard sconosciuto: ' + type);
    }

    setLeaderboard(resp.data.leaderboard);
  }, [type, date]);

  useEffect(() => {
    setLoading(true);
    fetchLeaderboard().finally(() => setLoading(false));
  }, [fetchLeaderboard, setLoading]);

  return (
    <div>
      <GoBack />
      <Header
        type={type}
        date={date}
        onTypeChange={(val) => setType(val)}
        onDateChange={(val) => setDate(val)}
      />
      <Conteiner>
        {loading && <p style={{ textAlign: 'center', fontSize: 20 }}>Caricamento in corso...</p>}
        {!loading && <LeaderboardRows leaderboard={leaderboard} showIcons />}
      </Conteiner>
    </div>
  );
}

export default RollOfHonor;
