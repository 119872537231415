import styled from 'styled-components';

export const ContentWrapper = styled.button`
  padding: 32px 5px;
  display: block;
  margin: 30px auto;
  width: 92vw;
  background-color: #B40000;
  border: none;
  color: #FFFFFF;
  border-radius: 19px;
  text-align: center;
  max-width: 640px;
`;

export const Text = styled.p`
  font-size: ${p => p.size}px;
  margin-top: ${p => p.mTop}px;
  margin-bottom: ${p => p.mBot}px;
  color: ${p => p.color};
`;

export const PositionText = styled.h2`
  font-weight: 800;
  font-size: 28px;
`;
