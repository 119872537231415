import styled from "styled-components";

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, #da0000, #b60000);
  width: auto;
  height: 40px;
  border-radius: 6px;
  cursor: pointer;
`;

export const Text = styled.p`
  font-size: 12px;
  color: white;
  padding: 0px 10px;
`;