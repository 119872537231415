import React from "react";
import { ReactComponent as Complete } from "../../assets/action-completed.svg";
import styled from "styled-components";

export const Done = () => {
  return (
    <Row>
      <Complete width={130} height={130} />
    </Row>
  );
};

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 33px;
  margin-bottom: 28px;
`;

export const Wrapper = styled.div`
  width: auto;
  height: auto;
  background-color: #B40000;
  border-radius: 18px;
  margin-bottom: 18px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 10px 10px;
  padding-top: 110px;
  padding-bottom: 38px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 18px;
  padding-top: 18px;
  background-color: #F2F3F8;
`;

export const RegisteredText = styled.p`
  font-size: 25px;
  text-align: center;
  color: white;
`;

export const ExtraboldText = styled.p`
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  color: white;
`;

export const EndText = styled.p`
  font-size: 14px;
  text-align: center;
  color: white;
  width: 85%;
  margin-left: 30px;
  margin-bottom: 68px;
`;

export const NextText = styled.p`
    font-size: 10px;
    text-align: center;
    color: white;
`
