import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/ipoh-logo.svg";
import { ReactComponent as MenuButton } from "../../assets/hamburger-menu.svg";
import { ReactComponent as Guy } from "../../assets/menu-profile.svg";
import { ReactComponent as Pencil } from "../../assets/menu-edit.svg";
import { ReactComponent as Dots } from "../../assets/menu-calendar.svg";
import { ReactComponent as Bell } from "../../assets/menu-bell.svg";
import { ReactComponent as Cup } from "../../assets/menu-cup.svg";
import { ReactComponent as Team } from "../../assets/menu-team.svg";
import { ReactComponent as Cross } from "../../assets/close-side.svg";

export const Close = () => {
  return <Cross width={14} height={14} />;
};

export const Profile = () => {
  return <Guy width={20} height={20} />;
};

export const Edit = () => {
  return <Pencil width={20} height={20} />;
};

export const Calendar = () => {
  return <Dots width={20} height={20} />;
};

export const Credits = () => {
  return <Bell width={20} height={20} />;
};

export const Leaderboard = () => {
  return <Cup width={20} height={20} />;
};

export const MyTeam = () => {
  return <Team width={20} height={20} />;
};

export const HeaderBar = styled.div`
  background-color: #fff;
  padding: 11px 0;
`;

export const Container = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  margin: 0 auto 0;
`;

export const SideMenuContainer = styled.div`
  display: flex;
  width: 93%;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 37px;
  margin-left: 20%;
  width: auto;
`;

export const SideMenuCategory = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  margin-bottom: 29px;
  width: 164px;
`;
export const SideMenuText = styled.p`
  padding-left: 10px;
  font-size: 17px;
  color: black;
`;

export const Menu = styled.div`
  padding: 15px 0;
  cursor: pointer;
`;

export const CloseMenu = styled.div`
  padding: 15px 0;
  margin-left: 0px;
`;

export const IpohLogo = () => {
  return <Logo width={96} height={39} />;
};

export const SideMenuButton = () => {
  return <MenuButton width={23} height={13} />;
};
