import React from "react";
import { ReactComponent as Divisor } from "../../assets/divisor.svg";
import { ReactComponent as WhiteClose } from "../../assets/white-close.svg";
import { ReactComponent as Cross } from "../../assets/close-side.svg";
import styled from "styled-components";
import Arrow from "../../assets/select-arrow.svg";

export const NextDivisor = () => {
  return <Divisor width={"100%"} height={1} />;
};

export const Close = () => {
  return <WhiteClose width={11} height={11} />;
};

export const Cancel = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', width: 277, marginBottom: 10}}>
      <Cross width={14} height={14} />
    </div>
  );
};

export const Error = () => {
  return (
    <ErrorDiv>
      <Close />
      <ErrorText>Ci sono degli errori, verifica i campi</ErrorText>
    </ErrorDiv>
  );
};

export const Sample = styled.input.attrs({ type: "input" })`
  display: none;
  margin-bottom: 31px;
`;

export const GrayText = styled.p`
  font-size: 16px;
`
export const AutocompleteWrapper = styled.div`
  display: contents;
  & > span {
    display: contents;
    & > input {
      @media only screen and (max-width: 768px) {
        width: auto;
      }
    }
  }
`;

export const ErrorDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 3.4px;
  background-color: #b40000;
  width: 260px;
  height: 37px;
  padding-left: 10px;
  margin-bottom: 26px;
`;

export const ErrorText = styled.p`
  color: white;
  font-size: 10px;
  margin-left: 11px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 18px;
  background-color: #fff;
  width: auto;
  height: auto;
  padding-top: 53px;
  padding-left: 25px;
  padding-right: 25px;
`;

export const ErrorMessage = styled.p`
  color: #b40000;
  font-size: 12px;
  margin-bottom: 31px;
`;

export const Wrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 18px;
  background-color: #fff;
  width: auto;
  height: auto;
  padding-top: 24.6px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 20px;
`;

export const Button = styled.input.attrs((props) => ({
  type: "submit",
}))`
  background-image: linear-gradient(to right, #da0000, #b60000);
  width: auto;
  height: auto;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 14px;
  color: white;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  border: 1px solid silver;
  cursor: pointer;
`;

export const NextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 27px;
`;

export const RedText = styled.p`
  font-size: 12px;
  color: #b40000;
`;

export const ShortText = styled.p`
  font-size: 16px;
  color: #808080;
  margin-bottom: 3px;
`;

export const DescriptionText = styled.p`
  font-size: 14px;
  color: #000000;
  margin-bottom: 14px;
`;

export const InputText = styled.input.attrs((props) => ({
  type: "text",
}))`
  background-color: ${(props) => props.$isError && "#E5CACA"};
  border-color: ${(props) => (props.$isError ? "#B40000" : "#808080")};
  margin-top: 12.5px;
  margin-bottom: 31px;
  width: 255px;
  height: 37px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3.4px;
  color: #808080;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding-left: 12px;
  padding-right: ${(props) => props.right && "23px"};
  ::placeholder {
    text-align: ${(props) => props.right && "right"};
  }

  @media only screen and (max-width: 768px) {
    width:auto;
  }
`;

export const InputTextArea = styled.textarea.attrs((props) => ({
  color: "#808080",
  fontSize: 14,
}))`
  margin-top: 12.5px;
  margin-bottom: 26.5px;
  width: 252px;
  height: 76px;
  background-color: ${(props) => props.$isError && "#E5CACA"};
  border-color: ${(props) => (props.$isError ? "#B40000" : "#808080")};
  border-width: 1px;
  border-style: solid;
  border-radius: 3.4px;
  color: #808080;
  resize: none;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding-left: 12px;
  padding-top: 12px;
`;

export const InputNumber = styled.input.attrs((props) => ({
  type: "tel",
  color: "#808080",
  fontSize: 12,
}))`
  margin-top: 12.5px;
  margin-bottom: 25px;
  width: 255px;
  height: 37px;
  background-color: ${(props) => props.$isError && "#E5CACA"};
  border-color: ${(props) => (props.$isError ? "#B40000" : "#808080")};
  border-width: 1px;
  border-style: solid;
  border-radius: 3.4px;
  color: #808080;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding-left: 12px;
  @media only screen and (max-width: 768px) {
    width:auto;
  }
`;

export const InputSelect = styled.select`
  margin-top: 12.5px;
  margin-bottom: 25px;
  width: 271px;
  height: 41px;
  background-color: ${(props) => (props.$isError ? "#E5CACA" : "white")};
  border-color: ${(props) => (props.$isError ? "#B40000" : "#808080")};
  border-width: 1px;
  border-style: solid;
  border-radius: 3.4px;
  color: #808080;
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  padding-left: 12px;
  appearance: none;
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  background-position: right 13px top 15px;
  @media only screen and (max-width: 768px) {
    width:100%;
  }
`;

export const RedRectangle = styled.div`
  width: 5px;
  height: 33px;
  background-color: #b40000;
`;

export const HeaderContainer = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleContainer = styled.p`
  font-size: 16px;
  color: black;
  margin-left: 13px;
  flex-grow: 1;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  width: ${(props) => (props.short ? "60%" : "100%")}
  ;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const SecondRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width:100%;
  }
`;

export const ColumnFix = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  @media (min-width: 768px) {
    margin-top: 0px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  padding-top: 18px;
  background-color: #f2f3f8;
  align-items: center;
`;
