import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 299px;
  height: 100px;
  background-color: #fff;
  border-radius: 14px;
  margin-bottom: 13px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 10px 10px;
  @media (min-width: 768px) {
    width: 680px;
  }
  align-items: center;
  padding-left: 15px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 13px;
  margin-left: 17px;
  width: 182px;
  @media (min-width: 768px) {
    width: 400px;
  }
`;

export const Title = styled.p`
  font-size: 14px;
  color: black;
  margin-bottom: 7.5px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const DescriptionText = styled.p`
  font-size: 12px;
  color: #808080;
  @media (min-width: 768px) {
    font-size: 16px;
  }
`;
