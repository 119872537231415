import React from "react";
import { Button, Text } from "./gradient.button.style";

function Gradient(props) {
  const { text } = props;
  return (
    <Button>
      <Text>{text}</Text>
    </Button>
  );
}

export default Gradient;
