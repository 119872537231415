import styled from 'styled-components';

export default styled.button`
  padding: 12px 10px;
  border-radius: 6px;
  color: #FFFFFF;
  background: rgb(218,0,0);
  background: linear-gradient(90deg, rgba(218,0,0,1) 0%, rgba(159,0,0,1) 100%);
  border: none;
`;
