/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect, useCallback } from "react";
import Container from "../../components/container.component";
import Reward from "../../components/reward.component";
import Api from "../../services/api.service";
import GoBack from "../../components/goback.component";
import Medals from "../../components/medals/medals";
import Tooltip from "rc-tooltip";
import Loader from "react-loader-spinner";
import {
  Body,
  UserWrapper,
  Username,
  Role,
  UserInfo,
  TeamWrapper,
  TeamText,
  TeamName,
  IpohWrapper,
  IpohText,
  IpohTime,
  IconWrapper,
} from "../home/home.style";
import { TooltipDiv, TooltipText } from "../achievements/achievements.style";
import styled from "styled-components";

const Location = styled.div`
  position: relative;
  display: flex;
  color: black;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  justify-content: space-between;
  ${(props) => !props.last && `
  &::after {
    content: "";
    position: absolute;
    height: 1px;
    background-color: rgb(223, 227, 231);
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    width: calc(100% - 64px);
    margin-left: 32px;
  }
  `}
`;

function Profile(props) {
  const [profile, setProfile] = useState();
  const [badges, setBadges] = useState();
  const [unlockedBadges, setUnlockedBadges] = useState();
  const [team, setTeam] = useState();
  const [currentUser, setCurrentUser] = useState();

  const getBadges = async (id) => {
    try {
      const res = await Api.getBadges(id);
      let chunk_size = 3;
      let tempArray = res.data.unlocked
        .map(function (e, i) {
          return i % chunk_size === 0
            ? res.data.unlocked.slice(i, i + chunk_size)
            : null;
        })
        .filter(function (e) {
          return e;
        });
      setUnlockedBadges(res.data.unlocked);
      setBadges(tempArray);
    } catch (e) {
      // console.log(e.message);
    }
  };

  const teamRetriever = async (id) => {
    const res = await Api.getProfile(profile.user.tutor_id);
    setTeam(res.data.user.name);
  };

  const getProfile = async (id) => {
    try {
      const res = await Api.getProfile(id);
      setProfile(res.data);
    } catch (e) {
      // console.log(e.message);
    }
  };

  useEffect(() => {
    getProfile(props.location.state);
    getBadges(props.location.state);
  }, []);

  useEffect(() => {
    if (profile !== undefined) {
      if (profile.user.role === "organizer") {
        teamRetriever(profile.user.tutor_id);
      } else {
        setTeam(profile.user.manager.name);
      }
    }
  }, [profile]);

  const getCurrentUser = useCallback(async () => {
    const { data: { user } } = await Api.getProfile();
    setCurrentUser(user);
  }, []);

  useLayoutEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  if (badges && profile) {
    return (
      <>
        <GoBack />
        <Body>
          <Container
            title={"Informazioni personali"}
            content={
              <>
                <UserWrapper>
                  <div
                    style={{
                      width: 127,
                      height: 127,
                      borderRadius: 100,
                      borderWidth: 4,
                      borderStyle: "solid",
                      borderColor: "#b40000",
                      backgroundImage: `url(${
                        "https://admin.ipoh.app/" + profile.user.user_pic_url
                      })`,
                      backgroundSize: "cover",
                    }}
                  />
                  <Username>{profile.user.name}</Username>
                  <Role>
                    {profile.user.role === "organizer"
                      ? "organizzatore"
                      : profile.user.role === "manager"
                      ? "responsabile"
                      : profile.user.role}
                  </Role>
                </UserWrapper>
                <UserInfo>
                  <TeamWrapper>
                    <TeamText>Team</TeamText>
                    <TeamName>{team}</TeamName>
                  </TeamWrapper>
                  <IpohWrapper>
                    <IpohText>in Ipoh da</IpohText>
                    <IpohTime>{profile.user.assumption_date}</IpohTime>
                  </IpohWrapper>
                </UserInfo>
                <Medals profile={profile} />
              </>
            }
          ></Container>
          <Container
            title={`Associazioni create ${profile.locations.length}`}
            content={
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 23,
                marginTop: 23,
                fontSize: 14,
              }}>
                {profile.locations.map((location, index) => 
                  <Location key={location} last={index === profile.locations.length - 1}>
                    <div>{location.name}</div>
                    <div>Creata il {location.created_at}</div>
                  </Location>)}
              </div>
            }
          />
          <Container
            title={"Riconoscimenti Ottenuti   " + unlockedBadges.length + " "}
            content={
              <>
                {badges.length > 0 ? badges.map((array) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: 23,
                      }}
                    >
                      {array.map((badge) => {
                        return (
                          <>
                            <Tooltip
                              trigger={["click"]}
                              placement="top"
                              align={{
                                offset: [4, 30],
                              }}
                              transitionName={"rc-tooltip-zoom"}
                              overlay={
                                <TooltipDiv>
                                  <TooltipText>{badge.name}</TooltipText>
                                </TooltipDiv>
                              }
                            >
                              <IconWrapper>
                                <div style={{ cursor: "pointer" }}>
                                  <Reward
                                    icon={`https://admin.ipoh.app/${badge.icon.url}`}
                                  />
                                </div>
                              </IconWrapper>
                            </Tooltip>
                          </>
                        );
                      })}
                    </div>
                  );
                }) : <div style={{height: 50}} />}
              </>
            }
          ></Container>
        </Body>
      </>
    );
  } else
  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        marginTop: "20%",
      }}
    >
      <Loader type="Oval" color="#B40000" height={100} width={100} />
    </div>
  );
}

export default Profile;
