import styled from "styled-components";

export const GrayImage = styled.img`
  filter: grayscale(1);
  width: 82px;
  height: 82px;
`;

export const TooltipDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100px;
`;

export const TooltipText = styled.p`
  font-size: 12px;
  width: 80px;
  text-align: center;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f2f3f8;
  padding-bottom: 30px;
`;

export const RedBand = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #b40000;
  padding-top: 16px;
  padding-bottom: 22px;
`;

export const RedBandText = styled.p`
  font-size: 25px;
  color: white;
`;

export const RedBandSubText = styled.p`
  font-size: 12px;
  color: white;
  margin-top: 10px;
`;

export const AchievementsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 15px;
  margin-top: 25px;
`;

export const AchievementColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AchievementText = styled.p`
  font-size: 12px;
  color: black;
  width: 96px;
  margin-top: 12px;
  text-align: center;
`;
