import React from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';

export const Conteiner = styled.div`
  margin: 24px auto;
  max-width: 720px;
  @media (max-width: 780px) {
    max-width: 88vw;
  }
`;

export const Header = ({ type, date, onTypeChange, onDateChange }) => (
  <HeaderWrapper>
    <h1><span style={{ color: '#FCEE21' }}>Albo d’oro</span> Classifiche</h1>
    <HeaderInput 
      value={type === 'association' ? 'Associazioni Registrate' : 'Manifestazioni Effettuate'} 
      onNext={() => type === 'association' ? onTypeChange('manifestation') : onTypeChange('association')}
      onPrev={() => type === 'association' ? onTypeChange('manifestation') : onTypeChange('association')}
    />
    <div style={{ marginBottom: 25 }} />
    <HeaderInput
      value={date.format('MMMM YYYY')}
      onNext={() => {
        if (!moment().subtract(1, 'months').isSameOrBefore(date, 'month')) {
          onDateChange(moment(date).add(1, 'month'));
        }
      }}
      onPrev={() => onDateChange(moment(date).add(-1, 'month'))}
    />
  </HeaderWrapper>
);

const HeaderWrapper = styled.div`
  padding: 15px 0;
  background-color: #B40000;

  & h1 {
    font-size: 27px;
    margin-bottom: 20px;
    color: #FFFFFF;
    text-align: center;
    text-transform: capitalize;
  }
`;

const HeaderInput = ({ value, onNext, onPrev }) => (
  <InputWrapper>
    <ArrowWrapper onClick={onPrev}>
      <Arrow left />
    </ArrowWrapper>
    <h3>{value}</h3>
    <ArrowWrapper onClick={onNext}>
      <Arrow right />
    </ArrowWrapper>
  </InputWrapper>
);

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 3px;

  & h3 {
    font-size: 24px;
    color: #FFFFFF;
    text-transform: capitalize;
    text-align: center;
  }
`;

const ArrowWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
`;

const Arrow = styled.div`
  height: 14px;
  width: 12px;
  background-color: #FFFFFF;
  ${p => p.left && css`
    clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
  `}
  ${p => p.right && css`
    clip-path: polygon(0% 0%, 0% 100%, 100% 50%);
  `}
`;
