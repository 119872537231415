import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";

import AppContext from '../../services/context.service';

import { ReactComponent as LogoSvg } from '../../assets/logo.svg';
import {
    Conteiner,
    Row,
    Text,
    TextInput,
    SubmitButton,
    LogoWrapper,
    ErrorText
} from './login.style';

function LoginScreen(props) {
    const { resetPassword } = useContext(AppContext);
    const history = useHistory();
    const [resetted, setResetted] = useState(false);
    const { register, handleSubmit, errors, setError } = useForm({
        defaultValues: {
            email: '',
            password: '',
        }
    });

    const email = new URLSearchParams(props.location.search).get("email");
    const token = new URLSearchParams(props.location.search).get("token");

    async function onSubmit({ password, repassword }) {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        try {
            if (password !== repassword) {
                throw new Error();
            }
            await resetPassword(email, token, password, repassword);
            setResetted(true);
        } catch (e) {
            const { response } = e;
            if (!response) {
                return setError('mainError', 'unknown', 'Le password non sono uguali');
            }
            switch (response.status) {
                case 422: setError('password', '422', 'Password non valida'); break;
                default: break;
            }
        }
    }

    if (!resetted) {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <LogoWrapper><LogoSvg /></LogoWrapper>
                <Conteiner>
                    <Text fontSize='27' color='#1A1A1A'>Reset password</Text>
                    <Text margin='10px 0 5px 0' style={{ textAlign: 'center' }}>Inserisci la nuova password di accesso</Text>

                    <ErrorText>{errors.mainError && errors.mainError.message}</ErrorText>

                    <TextInput
                        name="password"
                        autoComplete="on"
                        error={errors.password}
                        type="password"
                        label="Password"
                        ref={register({ required: true })}
                    />
                    <TextInput
                        name="repassword"
                        autoComplete="on"
                        error={errors.repassword}
                        type="password"
                        label="Conferma password"
                        ref={register({ required: true })}
                    />

                    <Row margin='0 0 16px 0'>
                        <div style={{ cursor: 'pointer' }} onClick={() => history.push({ pathname: "/recover" })}><Text>Recupera la password</Text></div>
                    </Row>

                    <SubmitButton>Reset</SubmitButton>
                </Conteiner>
            </form>
        );
    } else {
        return (
            <>
                <LogoWrapper><LogoSvg /></LogoWrapper>
                <Conteiner>
                    <Text fontSize='27' color='#1A1A1A'>Password resettata</Text>
                    <Text margin='15px 0 5px 0' style={{ textAlign: 'center' }}>Torna al login per effettuare l'accesso con la nuova password</Text>

                    <SubmitButton onClick={() => history.push("/login")}>Torna al login</SubmitButton>
                </Conteiner>
            </>
        );
    }
}

export default LoginScreen;
