import React from "react";
import { NumberDiv } from "../screens/association_list/association_list.style";

function Paging(props) {
  const { page, results } = props;
  if (results === 1) {
    return <NumberDiv isActive>{page}</NumberDiv>;
  } else if (results === 2) {
    return (
      <>
        {page < results ? (
          <NumberDiv isActive>{page}</NumberDiv>
        ) : (
          page === results && <NumberDiv>{page - 1}</NumberDiv>
        )}
        {page + 1 === results ? (
          <NumberDiv>{page + 1}</NumberDiv>
        ) : page === results && (
          <NumberDiv isActive>{page}</NumberDiv>
        )}
      </>
    );
  } else if (results >= 3) {
    return (
      <>
        {page + 2 <= results ? (
          <NumberDiv isActive>{page}</NumberDiv>
        ) : page + 1 > results ? (
          <NumberDiv>{page - 2}</NumberDiv>
        ) : (
          page + 2 > results && <NumberDiv>{page - 1}</NumberDiv>
        )}
        {page + 1 < results ? (
          <NumberDiv>{page + 1}</NumberDiv>
        ) : page + 1 === results ? (
          <NumberDiv isActive>{page}</NumberDiv>
        ) : (
          page + 1 > results && <NumberDiv>{page - 1}</NumberDiv>
        )}
        {page + 2 <= results ? (
          <NumberDiv>{page + 2}</NumberDiv>
        ) : page + 1 === results ? (
          <NumberDiv>{page + 1}</NumberDiv>
        ) : (
          page + 1 > results && <NumberDiv isActive>{page}</NumberDiv>
        )}
      </>
    );
  }
}

export default Paging;
