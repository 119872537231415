import React from 'react';
import {ReactComponent as SearchIcon} from '../../assets/search-icon.svg';
import AsyncSelect from 'react-select/async';

function Autocomplete({ searchFunction, highlightError, defaultOptions=false, noOptionsMessage='Non ci sono opzioni', ...props }) {
  async function loadOptions(inputValue, callback) {
    const data = await searchFunction(inputValue);
    callback(data);
  };

  return (
    <AsyncSelect 
      {...props}
      placeholder='cerca'
      defaultOptions={defaultOptions}
      cacheOptions
      loadOptions={loadOptions}
      noOptionsMessage={() => noOptionsMessage}
      components={{
        DropdownIndicator: () => <div style={{ margin: '0 7px' }}><SearchIcon height='22' width='22' /></div>
      }}
      styles={{
        dropdownIndicator: (base, _) => ({ ...base, color: '#808080' }),
        option: (base, _) => ({ 
          ...base, 
          color: '#000000', 
          backgroundColor: '#FFFFFF' 
        }),
        control: (base, _) => ({
          ...base,
          backgroundColor: highlightError ? '#E5CACA' : undefined,
          borderColor: highlightError ? '#B40000' : '#808080'
        })
      }}
    />
  );
}

export default Autocomplete;
